<template>

    <div id="timeline">
        <IEcharts id="echarts"
                :option="scatter"
                @ready="onReady"
                @click="onClick"
                :resizable="true"
        />
    </div>
</template>

<script>
    import IEcharts from 'vue-echarts-v3/src/full.js';
    // import 'echarts/lib/chart/scatter'
    // import 'echarts/lib/component/polar'
    import store from "../../store"
    import {getRepositoryTimeline,getRepositoryTimelineDaily} from "../../api";
    import {Topics} from "../../Topic";
    import {DECL_STMT, FOR, LOC, NOM} from "../../view/options/SupportedMetrics";

    export default {
        name: 'Timeline',
        components: {
            IEcharts
        },
        props: {},
        data: () => ({
            loading: true,
            version: 0,
            instance:null,
            scatter: {
                textStyle: {
                    color: '#ffff'
                },
                // legend: {
                //     padding: 0,
                //     itemGap: 0,
                //     data: [' ', ' ']
                // },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        label:{
                            show:true,
                            fontStyle:'oblique'
                        },
                    },
                    showContent:false,
                },
                grid: {
                    bottom: 0,
                    top:0,
                    containLabel: true
                },
                // dataZoom: [
                //     {
                //         show: true,
                //         handleIcon: 'M10.7,11.9v-1.3H9.3v1.3c-4.9,0.3-8.8,4.4-8.8,9.4c0,5,3.9,9.1,8.8,9.4v1.3h1.3v-1.3c4.9-0.3,8.8-4.4,8.8-9.4C19.5,16.3,15.6,12.2,10.7,11.9z M13.3,24.4H6.7V23h6.6V24.4z M13.3,19.6H6.7v-1.4h6.6V19.6z',
                //         handleSize: '80%',
                //         realtime: true,
                //         handleStyle: {
                //             color: '#fff',
                //             shadowBlur: 3,
                //             shadowColor: 'rgba(0, 0, 0, 0.6)',
                //             shadowOffsetX: 2,
                //             shadowOffsetY: 2
                //         },
                //         textStyle:{
                //             color: '#fff',
                //         }
                //     }
                // ],
                xAxis: {
                    data: [],
                    boundaryGap: false,
                    axisLine: {
                        show: false
                    },
                    axisLabel:  {
                        show:true,
                        interval: function (index) {
                            let nOfVersions =store.getters['view/getEvolutionVersions'];
                            switch (true) {
                                case nOfVersions<50:
                                    return index%2 === 0;
                                case nOfVersions<100:
                                    return index%5 === 0;
                                case nOfVersions<500:
                                    return index%10 === 0;
                                case nOfVersions<1000:
                                    return index%20 === 0;
                                case nOfVersions<1500:
                                    return index%40 === 0;
                                case nOfVersions<2000:
                                    return index%80 === 0;
                                case nOfVersions<10000:
                                    return index%160 === 0;
                                case nOfVersions<20000:
                                    return index%440 === 0;
                                case nOfVersions<40000:
                                    return index%880 === 0;
                            }
                            return index%10 === 0;
                        },
                    }
                },
                yAxis: {
                    data: [],
                    axisLine: {
                        show: false
                    }
                },
                series: []
            },
            supportedMetrics: [LOC,NOM,DECL_STMT,FOR]
        }),
        methods: {
            onReady(instance, ECharts) {
                this.setDataSeries()
                this.instance = instance
            },
            onClick(event, instance, ECharts) {
                let clickVersion = event.data[0]

                store.commit("view/evolutionVersion", clickVersion - 1)
                store.commit('view/evolutionVersionNext', clickVersion)

                store.getters['view/getCurrentView'].nextVersion()

            },
            symbolSizeFunction(val) {
                let sizeValue = val[2] * 10
                let nOfVersions = Number(store.getters['view/getEvolutionVersions']);
                switch (true) {
                    case nOfVersions<50:
                        return sizeValue * 2.5
                    case nOfVersions<100:
                        return sizeValue * 2.5
                    case nOfVersions<500:
                        return sizeValue * 2.5
                    case nOfVersions<1000:
                        return sizeValue * 2.5
                    case nOfVersions>1000:
                        return sizeValue * 2.5
                }
                return sizeValue
            },
            setDataSeries() {
                getRepositoryTimeline(this.$route.params.owner + '/' + this.$route.params.name).then(data => {
                    let dataSeries = []
                    let yAxisSeries = [];
                    let xAxisSeries = []
                    for (let [key, value] of Object.entries(data)) {
                        let versionNumber = key;
                        let versionData = value;
                        xAxisSeries.push(versionNumber)
                        for (let [key1, value1] of Object.entries(versionData)) {
                            let stringKey1 = this.supportedMetrics.filter(m=>m.be === key1)[0].fe
                            let finalValue = value1 > 0 ? value1 : 0;
                            if (yAxisSeries.indexOf(stringKey1) >= 0) {
                                dataSeries.push([Number(versionNumber), yAxisSeries.indexOf(stringKey1), finalValue])
                            } else {
                                yAxisSeries.push(stringKey1)
                                dataSeries.push([Number(versionNumber), yAxisSeries.indexOf(stringKey1), finalValue])
                            }
                        }
                    }
                    this.scatter.series.push({
                        name: '',
                        type: 'scatter',
                        data: dataSeries,
                        large:true,
                        symbolSize: this.symbolSizeFunction,
                        // hoverAnimation: false,
                        // animation: false,
                        markLine: {
                            animation:false,
                            data: [ { name: 0, xAxis: 1, label: {
                                    formatter: 0,
                                    position: 0
                                } } ],
                            symbol:["circle","none"],
                            silent:true,
                            lineStyle:{
                                type:'solid',
                                color: "#76b1fa",
                            },
                            label:{
                                show:true,
                                fontStyle:'oblique'
                            },
                        },
                        showSymbol: false
                    })
                    this.scatter.xAxis.data = xAxisSeries
                    this.scatter.yAxis.data = yAxisSeries
                    this.loading = false;
                })
            }
        },
        computed:{
            getCurrentVersion(){
                return store.getters['view/getEvolutionVersion'];
            },
            getHighlightSeries(){
                return store.getters['timeline/getHighlightSeries']
            }
        },
        watch:{
            getCurrentVersion(change){
                this.version = change
                this.scatter.series[0].markLine.data[0] = { name: change, xAxis: change, label: {
                        formatter: change,
                        position: 0,
                    } }
                this.instance.setOption(this.scatter)
            },
            getHighlightSeries(change){
                let finalData = []
                change.forEach(index => this.scatter.series[0].data.filter(x=>x[0] === index).forEach(res => {
                        finalData.push(res)
                    })
                )
                this.scatter.series[1] =
                    {
                        type: 'scatter',
                        symbolSize: this.symbolSizeFunction,
                        data: finalData,
                        // hoverAnimation: false,
                        // animation: false,
                        itemStyle:{
                            color: "#fffc55"
                        }
                    }
                this.instance.setOption(this.scatter)
            }
        },
        mounted() {
            console.log('timelone mounted')
        }
    }
</script>

<style scoped>
    #timeline {
        width: 100%;
        height: 120px;
        margin-bottom: 5px;
    }
</style>
