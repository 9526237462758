<template>
    <BaseLayout v-if="socket">
        <b-col cols="6">
            <ViewCanvas
                    :socket="socket"
                    :title="'ClassHistoryView'"
                    :viewType="viewType"
            ></ViewCanvas>
        </b-col>
        <b-col cols="6">
            <RepositoryTitle/>
            <M3ClassData/>
        </b-col>
    </BaseLayout>
</template>

<script>
    import ViewCanvas from "./M3Canvas";
    import {Socket} from "../../Socket";
    import BaseLayout from "../BaseLayout";
    import M3ClassData from "../shared/M3ClassData";
    import RepositoryTitle from "../evolution/RepositoryTitle";

    export default {
        name: "ClassHistoryView",
        components: {RepositoryTitle, M3ClassData, BaseLayout, ViewCanvas},
        data: function () {
            return {
                socket: null,
                viewType: ClassHistoryView
            }
        },
        methods: {
            async initSocket() {
                let socket = new Socket();
                socket = await socket.connect();
                return socket;
            }

        },
        async mounted() {
            this.socket = await this.initSocket();
        }
    }
</script>

<style scoped>

    #tmp {
        padding: 25px;
    }

</style>
