import {
    ArcRotateCamera, BackgroundMaterial,
    Camera, Color3, CubicEase, EasingFunction,
    Engine,
    FreeCamera,
    HemisphericLight,
    Light,
    Mesh, PointLight,
    Animation,
    Scene, SpotLight, Texture,
    Vector3, HighlightLayer, SceneInstrumentation, Constants, DirectionalLight, Color4, StandardMaterial
} from "@babylonjs/core";
import {Socket, SocketData} from "../Socket";
import {Topics} from "../Topic";
import {SkyMaterial} from "@babylonjs/materials";
import {showWorldAxis} from "../util/axis-util";
import store from "../store"
import {addMeshDoubleClickCameraAction} from "../glyphs/utils/action";
import {AdvancedDynamicTexture, TextBlock} from "@babylonjs/gui";
import {Text} from "./../glyphs/Text"
import {ALL, CHANGES_ONLY, CLASS_ONLY, REGEX, NONE, PACKAGE_ONLY} from "./options/SettingsModes";
import {Cuboid, CustomMesh} from "../glyphs/Cuboid";
import {M3Class} from "../glyphs/M3Class";
import {EvolutionMatrixVersionedView} from "./EvolutionMatrixVersionedView";

export abstract class View {
    protected repoId: number;
    protected _socket: Socket;

    protected _canvas: HTMLCanvasElement;
    protected _engine: Engine;
    protected _scene: Scene;
    protected _camera: Camera | ArcRotateCamera;
    protected _lights: Light[] = [];
    protected _instrumentation: SceneInstrumentation|null = null;

    protected ground:Mesh;
    protected sky:Mesh;


    protected _highlightLayer: HighlightLayer;
    public highlightedMeshes: CustomMesh[] = [];


    public adt:AdvancedDynamicTexture;
    public ovTexts: Text[] = [];

    public elisionMeshes: CustomMesh[] = []

    public constructor(canvasElement: string, repoId: number, socket: Socket) {
        this.repoId = repoId;
        this._socket = socket;
        this._canvas = <HTMLCanvasElement>document.getElementById(canvasElement);
        this._engine = new Engine(this._canvas, true,{doNotHandleContextLost: true},true);
        this._scene = this.createScene();
        this._camera = this.createArcCamera(this._scene, this._canvas);
        this.createBaseLight(this._scene);
        this.ground = this.createGround(this._scene);
        this.sky = this.createSkybox("skybox", "./assets/texture/skybox/TropicalSunnyDay", this._scene);

        this._highlightLayer = new HighlightLayer("hl1",  this._scene,{
            mainTextureRatio: 1,
            mainTextureFixedSize: 2048,
            blurTextureSizeRatio: 1,
            blurVerticalSize: 1,
            blurHorizontalSize: 1,
            alphaBlendingMode:Constants.ALPHA_COMBINE,
            isStroke: true,
        } );
        this.adt = AdvancedDynamicTexture.CreateFullscreenUI("myUI", true, this._scene);
        // this.adt.idealWidth = 500
        if(process.env['NODE_ENV'] === "development") {
            this._instrumentation = new SceneInstrumentation(this._scene);
            // showWorldAxis(this._scene, 400);
        }
        this.addCanvasEvents();
    }

    public async animate(): Promise<void> {
        let counter = 0;
        this._scene.registerBeforeRender(()=>{
            // if(counter%20 == 0) {
            //     store.commit('viewDebug/fps', this._engine.getFps());
            //     store.commit('viewDebug/activeMeshes', this._scene.getActiveMeshes().length);
            //     store.commit('viewDebug/allMeshes', this._scene.meshes.length);
            //     counter = 0;
            // }
            // counter++;
            // if (this._camera instanceof ArcRotateCamera && store.getters['viewDebug/getRotation'].isRotating) {
            //     this._camera.alpha += store.getters['viewDebug/getRotation'].rotation;
            //     this.camerasArcBorderFunction()
            // }
        })
        this._engine.runRenderLoop(() => {
            this._scene.render();
        });
        window.addEventListener('resize', () => {
            this._engine.resize();
        });
    }

    abstract callback(x: any, obj: View): void

    protected createScene(): Scene {
        let scene = new Scene(this._engine);
        scene.autoClear = false; // Color buffer
        scene.autoClearDepthAndStencil = false; // Depth and stencil, obviously
        scene.blockMaterialDirtyMechanism = true;
        return scene
    }

    protected createArcCamera(scene: Scene, canvas: HTMLCanvasElement) {
        let camera = new ArcRotateCamera("camera", -Math.PI / 2, Math.PI / 3.4, 100, Vector3.Zero(), scene);
        camera.maxZ = 32000;
        camera.attachControl(canvas, false, true);
        camera.panningSensibility = 10;
        camera.lowerRadiusLimit = 10;
        // camera.upperRadiusLimit = 1000;
        camera.panningAxis = new Vector3(1,1,1);
        camera.allowUpsideDown = false;
        camera.upperBetaLimit = 0
        camera.upperBetaLimit = 1.4835298642;
        camera.lockedTarget = Mesh.CreateBox("targetCameraBox", 10, scene, false);
        camera.lockedTarget.setEnabled(false);
        return camera
    }

    protected createFreeCamera(scene: Scene, canvas: HTMLCanvasElement) {
        let camera = new FreeCamera("Camera", new Vector3(0, 120, -300), scene);
        camera.speed = 8;
        camera.attachControl(canvas, false);
        return camera
    }

    protected createGround(scene: Scene): Mesh {
        let ground = Mesh.CreateGround("ground", 10000, 10000, 64, this._scene, false);
        addMeshDoubleClickCameraAction(ground)
        return ground;
    }

    protected moveGroundToPoint(x:number,z:number){
        this.ground.position.x = x;
        this.ground.position.z = z;
    }

    protected createSkybox(name: string, fileName: string, scene: Scene): Mesh {
        var skyMaterial = new SkyMaterial("skyMaterial", scene);
        skyMaterial.backFaceCulling = false;
        skyMaterial.useSunPosition = true; // Do not set sun position from azimuth and inclination
        skyMaterial.sunPosition = new Vector3(0, 500, -300);

        var skybox = Mesh.CreateBox("skyBox", 100000.0, scene);
        skybox.material = skyMaterial;
        return skybox
    }

    protected async init(topic: Topics, callbackFunction: Function, obj: Object, callback: Function) {
        this._socket.topicHandler.registerCallback(topic, callbackFunction, obj);
        await this._socket.subscribe(topic);
        callback()
    }

    protected requestViewData(topic: Topics,data: SocketData) {
        this._socket.publish(topic, data)
    }

    protected notifyCanvasObjects(objects: number) {
        store.commit('canvasObjects/canvasObjectsLoaded', {objects: objects});
    }

    private addCanvasEvents() {
        this._canvas.onmouseover = function () {
            store.commit('viewDebug/isRotating', !store.getters['viewDebug/getRotation'].isRotating)
        };
        this._canvas.onmouseleave = function () {
            store.commit('viewDebug/isRotating', !store.getters['viewDebug/getRotation'].isRotating)
        }
    }

    private camerasArcBorderFunction() {
        if (this._camera instanceof ArcRotateCamera && this._camera.beta < 0.1) this._camera.beta = 0.1; else if ((this._camera instanceof ArcRotateCamera) && this._camera.beta > (Math.PI / 2) * 0.9) this._camera.beta = (Math.PI / 2) * 0.9;
    }

    static stopWorld(view:View) {
        view._scene.dispose();
        view._engine.dispose();
    }


    protected highlightMesh(mesh:Mesh){
        // this._highlightLayer.addMesh(mesh,(Color3.Yellow()).scale(.85))
        // this.highlightedMeshes.push(mesh);
        // this._highlightLayer.outerGlow = true;
        // this._highlightLayer.innerGlow = false;
        mesh.edgesColor = Color4.FromColor3(Color3.Yellow())
        mesh.enableEdgesRendering();
        mesh.edgesWidth = Math.log(mesh.scaling.x * mesh.scaling.y)*10
        this.highlightedMeshes.push(mesh)
    }

    protected resetOvTexts() {
        this.ovTexts.forEach(text => {
            text.dispose()
        });

        this.ovTexts = []
    }

    protected resetHiddenMeshes() {
        let nameMode = store.getters['settings/elisionMode']
        //
        // this.elisionMeshes.forEach(mesh => {
        //         if (nameMode == CHANGES_ONLY) {
        //             Cuboid.show(mesh)
        //             const index = this.elisionMeshes.indexOf(mesh);
        //             if (index > -1) {
        //                 this.elisionMeshes.splice(index, 1);
        //             }
        //
        //         }else if (nameMode == NONE){
        //             Cuboid.show(mesh)
        //             const index = this.elisionMeshes.indexOf(mesh);
        //             if (index > -1) {
        //                 this.elisionMeshes.splice(index, 1);
        //             }
        //         }
        //
        // });

    }

    protected resetHighlightedMeshes() {
        this.highlightedMeshes.forEach(mesh => {
            // let instance = this.classRoot.cloneToInstance(mesh);
            // mesh.dispose();
            if(store.getters['m3Class/currentMesh']) {
                if (store.getters['m3Class/currentMesh'].id != mesh.id) {
                    mesh.disableEdgesRendering()
                }else{
                    mesh.edgesColor = store.getters['m3Class/currentMeshHighlightColor'];
                }
            }else{
                if(mesh!=null) {
                    mesh.disableEdgesRendering()
                }
            }
            // this._highlightLayer.removeMesh(mesh)
        });
        // this._scene.cleanCachedTextureBuffer();
        // this._scene.clearCachedVertexData();

        this.highlightedMeshes = []
    }

    private createBaseLight(scene:Scene) {
        let light4 = new DirectionalLight("DirectionalLight", new Vector3(0, -1, 0), scene);
        light4.intensity=.5
        this._lights.push(light4)
    }

    protected addLight(scene:Scene, position:Vector3) {
        // console.log(position)
        let light = new PointLight("p-light-" + this._lights.length, position, scene);
        light.intensity=.2
        this._lights.push(light)

        light = new PointLight("p-light-" + this._lights.length, this._camera.position, scene);
        light.intensity=1
    }

    public resetMeshesToVersion(version:number){
        this._scene.meshes.filter(mesh=>mesh.id.startsWith("M3-")).forEach(mesh=>{
            let cMesh = <CustomMesh> mesh;
            this.adt.dispose()
            this.adt = AdvancedDynamicTexture.CreateFullscreenUI("myUI", true, this._scene);

            if(cMesh.repositoryVersion && cMesh.repositoryVersion >= version){
                cMesh.dispose(true,false)
            }else if (version == 0){
                cMesh.dispose(true, true)
            }
        })
    }

    public resetADT(view:EvolutionMatrixVersionedView){
        // this.adt.dispose()
        // this.adt = AdvancedDynamicTexture.CreateFullscreenUI("myUI", true, this._scene);
        // this.resetOvTexts()
        this.ovTexts.forEach(text => {
            // @ts-ignore
            text.label.text = M3Class.updateLabelPopup(text.mesh.config)
        })
    }

    public resetElision(view:EvolutionMatrixVersionedView){
        // this.adt.dispose()
        // this.adt = AdvancedDynamicTexture.CreateFullscreenUI("myUI", true, this._scene);
        // this.resetOvTexts()
        // @ts-ignore
        this._scene.meshes.filter((mesh:CustomMesh)=>mesh.id.startsWith("M3-")).forEach((mesh:CustomMesh) => {
            M3Class.displayElision(view,mesh.config,mesh)
        })
        this.elisionMeshes.forEach(mesh => {
            M3Class.displayElision(view,mesh.config,mesh)
        })
    }

}
