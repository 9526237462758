<template>
    <div class=" col-4" style="" :key="activeColorizationRecordedMeshes.toString()">
        <div class="col-md-12">
            <h4>Colorization Settings</h4>
        </div>
        <div class="form-row align-items-center col-md-12">

            <div class="col-auto">
                <label class="my-1 mr-2" for="inlineFormCustomSelectPref7">Mode</label>
                <select class="custom-select my-1 mr-sm-2" id="inlineFormCustomSelectPref7"
                        v-model="selected.colorization.mode">
                    <option v-for="(item,index) in colorizationModes" :value="item">{{item}}
                    </option>
                </select>

            </div>
            <div class="col-auto" v-if="selected.colorization.mode == 'Tags'">
                <div class="tags scrolly" style="height: calc(250px - 45px)">
                    <div class="inline tag" v-for="(tag,index) in tags" v-if="index<= limitTagsIndex"
                         v-on:click="setActiveTag" :id="'tag-'+tag" v-bind:key="'tag-'+tag">{{tag}}
                    </div>
                </div>
                <div>
                    <div class="inline tag" v-for="(tag,index) in activeColorizationTags"
                         :style="'background-color:rgb(' + tag.color.r*255 + ',' + tag.color.g*255 +','+tag.color.b*255+')' " v-on:click="setUnactiveTag" :id="'tag-' + tag.tag"
                         v-bind:key="'tag-'+tag.tag" v-if="tag!=null">{{tag.tag}}
                    </div>
                </div>
                <div class="clearBoth"></div>
                <div class="row" v-if="tags.length > limitTagsIndex">
                    <div class="col text-center">
                        <b-button variant="outline-primary" v-on:click="incrementIndex" class="plus">
                            <font-awesome-icon :icon="['fas', 'plus']"/>
                            Load More
                        </b-button>
                    </div>
                </div>

            </div>

            <div class="col-auto" v-if="selected.colorization.mode == 'Record'" style="width: 100%">
                <div class="inline tag" v-for="(mesh,index) in activeColorizationRecordedMeshes" v-if="mesh!=null"
                     :style="'background-color:' + toRgb(mesh.color)" v-on:click="setUnactiveRecord" :id="mesh.mesh.id"
                     v-bind:key="'tag-'+mesh.mesh.id">{{mesh.mesh.nameString}}
                </div>
                <template v-if="!selected.colorization.isRecording">
                    <b-button v-on:click="startRecording" variant="danger" class="plus" v-b-tooltip
                              title='In this mode you can click meshes you want to colorize'>
                        <font-awesome-icon :icon="['fas', 'record-vinyl']"/>
                        Select on scene
                    </b-button>
                </template>
                <template v-else>
                    <b-button v-on:click="stopRecording" variant="danger" class="plus">
                        <span class="blink">x
                        <font-awesome-icon :icon="['fas', 'record-vinyl']"/>
                        Stop selection...
                            </span>
                    </b-button>
                </template>
            </div>

            <div class="col-auto" v-if="selected.colorization.mode == 'Custom Regex'">
                <label class="my-1 mr-2" for="inlineFormCustomSelectPref9">Regex Pattern</label>
                <input type="text" id="inlineFormCustomSelectPref9" placeholder="Regex" value="" required
                       v-model="selected.colorization.regex.regex">
            </div>
        </div>
    </div>
</template>
<script>
    import store from '../../../store'

    export default {
        name: 'ColorizationSettings',
        props: {
            activeColorizationTags: {},
            colorizationModes: {},
            incrementIndex: {},
            limitTagsIndex: {},
            selected: {},
            setActiveTag: {},
            setUnactiveTag: {},
            setUnactiveRecord: {},
            tags: {},
            activeColorizationRecordedMeshes: {},
            change:{}
        },
        // data(){
        //     return{
        //         activeColorizationTagsC: this.activeColorizationRecordedMeshes
        //     }
        // },
        // computed:{
        //     getactiveColorizationTagsC(){
        //         return this.activeColorizationTagsC
        //     }
        // },
        // watch:{
        //     getactiveColorizationTagsC(change){
        //         console.log('changeeee')
        //         console.log(store.getters['settings/recordedMeshes'].length())
        //         this.getactiveColorizationTagsC = change
        //     }
        // },
        methods: {
            startRecording() {
                this.$bvModal.hide('modal-center')
                store.commit('settings/isRecording', true)
            },
            stopRecording() {
                store.commit('settings/isRecording', false)
            },
            toRgb(color){
                return 'rgb(' +color.r * 255+ ',' + color.g * 255 + ',' + color.b * 255 + ');'
            },

        },
    }
</script>
<style scoped>

    input {
        border: 1px solid transparent;
        background-color: #f1f1f1;
        padding: 10px;
        font-size: 16px;
    }

    input[type=text] {
        background-color: #f1f1f1;
        width: 100%;
    }

    input[type=submit] {
        background-color: DodgerBlue;
        color: #fff;
    }

    .autocomplete-items div {
        padding: 10px;
        cursor: pointer;
        background-color: #fff;
        border-bottom: 1px solid #d4d4d4;
    }

    .autocomplete-items div:hover {
        /*when hovering an item:*/
        background-color: #e9e9e9;
    }

</style>
<style>

    form {
        color: black !important;
    }

    .form-inline label {
        justify-content: left;
    }

    div.inline {
        float: left;
    }

    .clearBoth {
        clear: both;
    }

    .tag {
        padding: 0px 5px 0px 5px;
        margin: 2px 5px;
        border: 1px solid black;
        border-radius: 3px;
        cursor: pointer;
    }

    .plus {
        width: 100%;
        margin-top: 10px;
    }

    .scrolly {
        /*overflow-y: auto;*/
        position: relative;
        max-height: 200px;
        overflow: -moz-scrollbars-vertical;
        overflow-y: scroll;
    }

    .tag:hover {
        background-color: palegreen;
    }

    .blink {
        animation: blinking 1s step-start infinite;
    }

    @keyframes blinking {
        50% {
            opacity: 0;
        }
    }
</style>
