import {GetterTree} from 'vuex';
import {SceneState} from './types';
import {RootState} from '../types';

export const getters: GetterTree<SceneState, RootState> = {
    getRotation(state): any {
        let {rotation} = state;
        return rotation;
    },
    getActiveMeshes(state): any {
        let {activeMeshes} = state;
        return activeMeshes;
    },
    getAllMeshes(state): any {
        let {allMeshes} = state;
        return allMeshes;
    },
    getFps(state): number {
        return state.fps;
    },
    getSceneState(state): SceneState {
        return state;
    },
};
