import {Module} from 'vuex';
import {getters} from './getters';
import {actions} from './actions';
import {mutations} from './mutations';
import {SceneState} from './types'
import {RootState} from '../types';

export const state: SceneState = {
    activeMeshes: 0,
    allMeshes: 0,
    fps: 0,
    rotation: {
        isRotating: true,
        rotation: .01
    },
    error: false
};

const namespaced: boolean = true;

export const viewDebug: Module<SceneState, RootState> = {
    namespaced,
    state,
    getters,
    actions,
    mutations,
};
