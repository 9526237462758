import {GetterTree} from 'vuex';
import {SettingsState} from './types';
import {RootState} from '../types';
import {View} from "../../view/View";
import {Color3, LinesMesh, Vector3} from "@babylonjs/core";
import {CustomMesh} from "../../glyphs/Cuboid";

export const getters: GetterTree<SettingsState, RootState> = {

    commitMessage(state): boolean {
        let {canvasCommitMessage} = state.view;
        return canvasCommitMessage;
    },

    commitTerminal(state): boolean {
        let {canvasCommitTerminal} = state.view;
        return canvasCommitTerminal;
    },

    canvasTitle(state): boolean {
        let {canvasTitle} = state.view;
        return canvasTitle;
    },

    canvasEvolution(state): boolean {
        let {canvasEvolution} = state.view;
        return canvasEvolution;
    },

    depth(state): string {
        let {depth} = state.view;
        return depth;
    },

    height(state): string {
        let {height} = state.view;
        return height;
    },

    nameMode(state):string{
        let {mode} = state.names;
        return mode;
    },

    nameRegex(state):string{
        let {regex} = state.names;
        return regex;
    },

    nameText(state):string{
        let {text} = state.names;
        return text;
    },

    nameHoverEnabled(state):boolean{
        let {hoverEnabled} = state.names;
        return hoverEnabled;
    },
    elisionMode(state):string{
        let {mode} = state.elision;
        return mode;
    },

    elisionRegex(state):string{
        let {regex} = state.elision;
        return regex;
    },

    colorizationMode(state):string{
        let {mode} = state.colorization;
        return mode;
    },

    colorizationTags(state):{tag:string, color:Color3}[]{
        // console.log(state.colorization)
        let {tags} = state.colorization;
        return tags;
    },

    colorizationRegex(state):string{
        let {regex} = state.colorization.regex;
        return regex;
    },
    colorizationRegexColor(state):Color3{
        let {color} = state.colorization.regex;
        return color;
    },

    isRecording(state):boolean{
        let {isRecording} = state.colorization;
        return isRecording;
    },

    bumpSize(state):number{
        let {bumpSize} = state.view;
        return bumpSize;
    },
    minHeight(state):number{
        let {minHeight} = state.view;
        return minHeight;
    },

    minWidth(state):number {
        let {minWidth} = state.view;
        return minWidth;
    },

    recordedMeshes(state):{mesh:CustomMesh, color:Color3}[] {
        let {recordedMeshes} = state.colorization;
        return recordedMeshes;
    },

    all(state):SettingsState{
        return state
    }

};
