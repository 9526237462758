<template>
    <b-col cols="12" v-if="visible">
        <div>Selected mesh data:</div>
        <b-table :items="this.currentM3Class()" hover responsive small stacked striped></b-table>
    </b-col>
</template>

<script>
    import {mapGetters} from "vuex";

    export default {
        name: "M3ClassData",
        data() {
            return {
                visible: null
            }
        },
        methods: {
            ...mapGetters('m3Class', ['currentM3Class']),
        },
        mounted() {
            this.visible = true;
        }
    }
</script>

<style scoped>

</style>
