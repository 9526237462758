import {ActionTree} from 'vuex';
import {RootState} from '../types';
import {TimelineState} from "./types";
import {View} from "../../view/View";
import store from "../../store";
import {
    getRepositoryClassVersionTimelineHighlights,
    getRepositoryClassVersionTimelineHighlightsMode,
    getRepositoryPackageVersionTimelineHighlights, getRepositoryPackageVersionTimelineHighlightsMode
} from "../../api";


export const actions: ActionTree<TimelineState, RootState> = {

    async getHighlightPoints({commit,state}): Promise<void> {
        let res;
        if(store.getters['view/playingMode'] == 'all') {
            if (store.getters['m3Class/currentM3Class'].type == "class") {
                res = await getRepositoryClassVersionTimelineHighlights(<string>store.getters['view/getRepoId'], <string>store.getters['m3Class/currentM3Class'].name,)
            } else {
                res = await getRepositoryPackageVersionTimelineHighlights(<string>store.getters['view/getRepoId'], <string>store.getters['m3Class/currentM3Class'].name)
            }
        }else{
            if (store.getters['m3Class/currentM3Class'].type == "class") {
                res = await getRepositoryClassVersionTimelineHighlightsMode(<string>store.getters['view/getRepoId'], <string>store.getters['m3Class/currentM3Class'].name,store.getters['view/playingMode'] )
                // console.log('res',res)
            } else {
                res = await getRepositoryPackageVersionTimelineHighlightsMode(<string>store.getters['view/getRepoId'], <string>store.getters['m3Class/currentM3Class'].name,store.getters['view/playingMode'] )
            }
        }
        // @ts-ignore
        state.highlightSeries = res;
    },
    async resetState({commit,state}): Promise<void> {
        state.highlightSeries = []
    },
};
