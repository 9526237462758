import {GetterTree} from 'vuex';
import {M3ClassState} from './types';
import {RootState} from '../types';
import {AbstractMesh, Color4} from "@babylonjs/core";

export const getters: GetterTree<M3ClassState, RootState> = {
    currentM3Class(state): Object {
        const {m3Class} = state;
        // console.log('repo',repository)
        const frameM3Class = (m3Class) || '';
        return frameM3Class;
    },
    currentMesh(state): AbstractMesh|undefined {
        const {mesh} = state;
        return mesh;
    },
    currentMeshHighlightColor(state): Color4 {
        const {highlightColor} = state;
        return highlightColor;
    },
    getSelected(state): boolean {
        const {selected} = state;
        return selected;
    },
};
