import Vue from 'vue'
import Router from "vue-router";
import ClassHistoryView from "../components/canvas/ClassHistory.vue";
import Home from "../components/home/Home.vue";
import CommitHistoryView from "../components/canvas/CommitHistory.vue";
import EvolutionMatrixView from "../components/canvas/EvolutionMatrix.vue";
import Evolution from "../components/evolution/Evolution.vue";

Vue.use(Router);

export default new Router({
    routes:
        [
            {path: '/', name: 'home', component: Home},
            {path: '/classHistoryView/:id', name: 'classHistory', component: ClassHistoryView},
            {path: '/commitHistoryView/:id', name: 'commitHistory', component: CommitHistoryView},
            {path: '/evolutionMatrixView/:id', name: 'evolutionMatrix', component: EvolutionMatrixView},
            // {path: '/evolutionMatrixView/:id/:version', name: 'evolutionMatrix', component: EvolutionMatrixView},
            {path: '/evolution/:owner/:name/:mode', name: 'evolutionMatrixVersioned', component: Evolution},
            // {path: '/evolution/:owner/:name/{:mode}', name: 'evolutionMatrixVersionedDay', component: Evolution},
            // {path: '/evolution/:owner/:name/{:mode}', name: 'evolutionMatrixVersionedWeek', component: Evolution},
            // {path: '/evolution/:owner/:name/{:mode}', name: 'evolutionMatrixVersionedMonth', component: Evolution},
            // {path: '/evolution/:owner/:name/{:mode}', name: 'evolutionMatrixVersionedYear', component: Evolution},

        ]
    ,
    scrollBehavior() {
        return {x: 0, y: 0}
    }
});

