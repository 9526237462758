import {Module} from 'vuex';
import {getters} from './getters';
import {actions} from './actions';
import {mutations} from './mutations';
import {ViewState} from './types'
import {RootState} from '../types';
import {ALL, DAY} from "../../view/options/PlayingMode";

export const viewState: ViewState = {

    view: null,
    repoId: '',
    speed: 1,
    isPlaying: false,
    version: 1,
    markLineIndex:1,
    versions: 1,
    displayVersion: 1,
    versionsList: [],
    commit: null,
    hoverMesh: null,
    playingMode: ALL,
    nextVersion: 0,
    versionData: {
        modifiedClasses: 0,
        modifiedPackages: 0,
        modifiers: []
    },
    renameLines : {
        meshes: [],
    },
    socket: undefined,
    transcript:[],
    error: false,
    isLoading: false,
};

const namespaced: boolean = true;

export const view: Module<ViewState, RootState> = {
    namespaced,
    state:viewState,
    getters,
    actions,
    mutations,
};
