<template>
    <ViewCanvas
            :socket="socket"
            :title="'EvolutionMatrixVersionedView'"
            :viewType="viewType">
        <slot/>
    </ViewCanvas>
</template>

<script>
    import ViewCanvas from "./M3Canvas";
    import BaseLayout from "../BaseLayout";
    import M3ClassData from "../shared/M3ClassData";
    import RepositoryTitle from "../evolution/RepositoryTitle";
    import {EvolutionMatrixVersionedView} from "../../view/EvolutionMatrixVersionedView";
    import Player from "../shared/Player";

    export default {
        name: "EvolutionMatrixVersionedView",
        components: {BaseLayout, Player, RepositoryTitle, M3ClassData, ViewCanvas},
        props: ['socket'],
        data: function () {
            return {
                viewType: EvolutionMatrixVersionedView
            }
        }
    }
</script>

<style scoped>

    #tmp {
        padding: 25px;
    }

</style>
