<template>
    <div class="overlay" v-if="transcript.length > 0 && isVisible">
        <div class="panel">
<!--            <ul class="menu-list">-->
                <transition-group class="menu-list"  name="fade" tag="ul">
                    <li :key="val.key" v-for="(val,index) in transcript" :class="{ 'l' : parseInt(val.index) == version}">
<!--                        <transition name="fade">-->

                                <span class="time col-md-6">{{val.day}}</span>
                                <span class="author col-md-6">{{val.email.substring(0, val.email.lastIndexOf("@")) || "ERROR"}}</span>
<!--                        </transition>-->
                    </li>
                </transition-group>

<!--            </ul>-->
        </div>
    </div>

</template>

<script>
    import store from '../../../store'
    import {getCanvasDateAuthTerminal} from "../../../api";
    import {v4 as uuidv4} from 'uuid';

    export default {
        name: "CanvasCommitTerminal",
        data() {
            return {
                version: store.getters['view/getEvolutionVersion'],
                transcript: [],
                isVisible: store.getters['settings/commitTerminal'],

            }
        },
        computed: {
            currentVersion() {
                return store.getters['view/getEvolutionVersion']
            },
            async startTransc() {
                this.transcript = await getCanvasDateAuthTerminal(store.getters['view/getRepoId'], store.getters['view/getEvolutionVersion'])
            },
            getTerminalSettings(){
                return store.getters['settings/commitTerminal']
            }
        },
        watch: {
            currentVersion(change) {
                let flag = false;
                if(change == 0){
                    this.transcript = []
                    flag = true;
                }
                this.version = change;
                getCanvasDateAuthTerminal(store.getters['view/getRepoId'], change).then(data=> {
                    // console.log(data, this.transcript)
                    // if (!this.transcript.some(e => e.index === data[data.length - 1]['index'])) {
                    // if(!flag) {
                    //     this.transcript.push(data[data.length - 1]);
                    // }else{
                    if(this.transcript.length > 4) {
                        this.transcript.shift()
                    }
                    // console.log(this.transcript, this.transcript.length , this.transcript.length > 4)
                    this.transcript.push(data[data.length-1])
                        // this.transcript = data;
                    // }
                    // }
                    // this.transcript.shift()
                    // console.log(this.transcript)
                })
            },
            getTerminalSettings(change){
                this.isVisible = change;
            }
        },
        async mounted() {
            await this.startTransc;
        }
    }
</script>

<style scoped>

    .overlay {
        margin-bottom: 5px;
        position: absolute;
        bottom: 0;
        left: 0;
        padding: 5px;
        pointer-events: none;
        color: black;
        font-size: .75rem;
        width: 33.3%;

    }


    .menu-list {
        height: 100%;
        width: 100%;
        position: absolute;

        line-height: 2;
        padding: 0px !important;
        margin: 0px !important;

    }

    .menu-list li {
        /*justify-content: space-between;*/
        /*display: flex;*/
    }

    .time {
        width: 200px;
    }

    .l{
        font-size:1.55rem;

        height: 45px !important;

    }
    .author {
        color: #fa5b5a;
        text-align: right;
        width: 200px;
    }

    .neutral {
        color: #23a3c0;
        width: 70px;
        text-align: right;
    }

    .recovered {
        color: #8cff57;
        width: 70px;
        text-align: right;
    }


    .panel {
        padding: 5px;
        overflow: hidden;
        position: relative;
        width: 100%;
        min-height: 145px;
        /*max-height: calc(90vh - 160px - 60vh);*/
        background-color: rgba(255, 255, 255, 0.45);
        border-radius: 5px;
        word-wrap: break-word;
    }

    li{
        height: 20px;
    }



    .enter { transform: translateX(100%) }
    .enter-to { transform: translateX(0) }
    .slide-enter-active { position: absolute }

    .leave { transition: opacity .3s ease; }
    .leave-to {  transition: opacity .3s ease;}

    /*.slide-enter-active,*/
    /*.slide-leave-active { transition: all 150ms ease-in-out }*/

    .fade-enter-active, .fade-leave-active {
        transition: opacity .5s;
    }
    .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
        opacity: 0;
    }

</style>
