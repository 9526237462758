// TODO movement of the last picked object must be stored, if a new one is picked old one should go down
import {
    AbstractMesh,
    ActionManager,
    ArcRotateCamera, Color3,
    ExecuteCodeAction,
    InstancedMesh,
    Mesh,
    Plane,
    Vector3
} from "@babylonjs/core";
import store from "../../store";
import {moveTo} from "./animation";
import {Cuboid, CustomMesh} from "../Cuboid";


export default function addCuboidClickAction(clone: InstancedMesh | Mesh, callback: Function): void {
    if (clone.actionManager) {
        clone.actionManager.registerAction(new ExecuteCodeAction(ActionManager.OnPickDownTrigger, async evt => {
            if (evt != null && evt.meshUnderPointer != null) {
                if(store.getters['settings/isRecording']){
                    store.commit('settings/recordedMesh', {mesh: evt.meshUnderPointer, color: Color3.Random()})
                }

                if (store.getters['m3Class/getSelected'] && store.getters['m3Class/currentM3Class'].id == evt.meshUnderPointer.id) {
                    await store.dispatch("m3Class/resetState")
                } else {
                    await store.dispatch("m3Class/resetState")
                    let data = await callback(evt.meshUnderPointer.name);
                    if (data.type == "class" || data.type == "class history") {
                        store.commit('m3Class/m3ClassLoaded', {classObj: data, mesh: evt.meshUnderPointer})
                    } else if (data.type == "package") {
                        store.commit('m3Class/m3ClassLoaded', {classObj: data, mesh: evt.meshUnderPointer})
                    }
                    await store.dispatch('m3Class/classLoaded')
                }
            }
        }));

        clone.actionManager.registerAction(new ExecuteCodeAction(ActionManager.OnPointerOverTrigger, async evt => {
            let hoverEnabled = store.getters['settings/nameHoverEnabled']
            if (evt != null && evt.meshUnderPointer != null && hoverEnabled) {
                    let view = store.getters['view/getCurrentView']
                    let mesh = <CustomMesh> evt.meshUnderPointer
                    let oldHoverMesh = store.getters['view/hoverMesh']
                    if(oldHoverMesh!=null) {
                        oldHoverMesh.dispose()
                    }
                let textMode = store.getters['settings/nameText']
                let fText;
                if(textMode == 'authorname') {
                    fText = mesh.authorString;
                }else if (textMode == 'filename'){
                    fText = mesh.nameString;
                }else{
                    fText = mesh.nameString + " " +  mesh.authorString;
                }
                let text = Cuboid.makeText(mesh, view.adt, fText)

                store.commit('view/hoverMesh', text)
            }
        }));
        clone.actionManager.registerAction(new ExecuteCodeAction(ActionManager.OnPointerOutTrigger, async evt => {
            let hoverEnabled = store.getters['settings/nameHoverEnabled']
            if(hoverEnabled) {
                let oldHoverMesh = store.getters['view/hoverMesh']
                if (oldHoverMesh)
                    oldHoverMesh.dispose()
                store.commit('view/hoverMesh', null)
            }
        }))
    }
}

export function addActionManager(clone: AbstractMesh, actionManager: ActionManager): void {
    clone.actionManager = actionManager;
}


export function addMeshDoubleClickCameraAction(mesh: Mesh): void {
    let canvas = document.getElementById("v-EvolutionMatrixVersionedView");
    if (canvas != null) {
        canvas.addEventListener("dblclick", function (e) {
            var pickResult = mesh.getScene().pick(mesh.getScene().pointerX, mesh.getScene().pointerY);
            if (pickResult != null && pickResult.hit) {
                let camera = <ArcRotateCamera>mesh.getScene().getCameraByID("camera");
                if (camera != null && pickResult.pickedPoint != null) {
                    moveTo(camera.lockedTarget, pickResult.pickedPoint, 70);
                }
            }
        });
    }
}

function isPointInFrustum(frustumPlanes: Plane[], point: Vector3) {
    for (let i = 0; i < 6; i++) {
        if (frustumPlanes[i].dotCoordinate(point) < 0) {
            return false;
        }
    }
    return true;
}
