import {MutationTree} from 'vuex';
import {TimelineState} from './types';
import {View} from "../../view/View";
import {timelineState} from "./index";
import store from "../index";
import {LinesMesh, Vector3} from "@babylonjs/core";

export const mutations: MutationTree<TimelineState> = {
    setHighlightSeries(state, payload: any[]): any {
        state.highlightSeries = payload;
    },
    setYAxisLabels(state, payload: any[]): any {
        state.yAxisLabels = payload;
    },

    resetState(state): any {
        state.highlightSeries = []
    },
};
