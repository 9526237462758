<template>
    <b-container>
        <section class="min-vh-100" id="cover">
            <div id="cover-caption">
                <div class="container">
                    <div class="row text-white">
                        <div class="col-xl-6 col-lg-12 col-md-8 col-sm-10 mx-auto text-center form p-4">
                            <div class="google-logo text-center row text-center">
                                <img alt="logo" class="img-responsive" src="/assets/images/logo-light.png"
                                     width="100%">
                            </div>
                            <div class="px-2">
                                <div class="danger" v-if="log!==''"> {{log}}</div>
                                <form class="justify-content-center" v-on:submit="submitForm">
                                    <div class="form-group">
                                        <input class="form-control google-search" placeholder="Github repository" type="text"
                                               v-model="repoUrl"/>
                                    </div>
                                    <div class="btn-group">
                                        <button class="btn btn-default" type="submit">Analyze Repository</button>
<!--                                        <a class="btn btn-default" href="https://www.google.com/doodles" type="button">I'm-->
<!--                                            Feeling-->
<!--                                            Lucky</a>-->
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <b-row>
                        <RepositoryListing/>
                    </b-row>
                </div>
            </div>
        </section>
    </b-container>
</template>

<script>
    import RepositoryListing from "./RepositoryListing";
    import {postNewAnalysis} from "../../api";

    export default {
        name: "Home",
        components: {RepositoryListing},
        data() {
            return {
                repoUrl: "",
                log: ""
            }
        },
        methods: {
            async submitForm(event) {
                event.preventDefault();

                // if (this.repoUrl === "" || !this.repoUrl.startsWith("https://github.com/")) {
                //     this.log = "Please insert a GitHub URL";
                // } else {
                //     this.log = "We started analysing the repository";
                //     let response = await postNewAnalysis(this.repoUrl);
                //     this.repoUrl = ''
                // }

                this.$bvToast.toast(`this function has been temporarily disabled.`, {
                    title: 'Oops',
                    autoHideDelay: 5000,
                })

            }
        }
    }
</script>

<style scoped>
    .google-logo {
        margin-bottom: 2px;
        padding: 5px;
    }

    .google-search {
        padding: 20px 10px;
    }

    .google-search:focus {
        box-shadow: silver 0 2px 10px;
        border-color: silver;
    }

    .google-form .btn-group {
        padding: 20px 0;
    }

    .form-control {
        background-color: white;
        border: aquamarine;
        border-block-end-style: unset;
        border: 1px solid rebeccapurple;
        border-radius: 40px;
    }

    .btn-group > .btn {
        border-radius: 0;
        margin: 0 10px;
    }

    .btn {
        background-color: black;
        color: white;
        border: 1px solid;
        font-weight: 400;
    }

    .btn:hover {
        box-shadow: silver 0 2px 10px !important;
        border-color: silver !important;
    }

    .danger {
        color: rebeccapurple;
    }
</style>
