import Vue from 'vue';
import Vuex from 'vuex';
import {m3Repository} from './m3-repository';
import {RootState} from './types';
import {m3Class} from "./m3-class";
import {m3Commit} from "./m3-commit";
import {viewDebug} from "./view-debug";
import {view} from "./view";
import {timeline} from "./timeline";
import {settings} from "./settings";

Vue.use(Vuex);

export default new Vuex.Store<RootState>({
    state: {
        version: '1.0.0',
    },
    modules: {
        m3Repository,
        m3Class,
        m3Commit,
        viewDebug,
        view,
        timeline,
        settings
    },
    mutations: {},
    actions: {},
});
