import {GetterTree} from 'vuex';
import {TimelineState} from './types';
import {RootState} from '../types';
import {View} from "../../view/View";
import {LinesMesh, Vector3} from "@babylonjs/core";

export const getters: GetterTree<TimelineState, RootState> = {
    getHighlightSeries(state): any[] {
        let {highlightSeries} = state;
        return highlightSeries;
    },
    getYAxisLabels(state): any[] {
        let {yAxisLabels} = state;
        return yAxisLabels;
    },

};
