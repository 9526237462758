import {SocketReqType} from "./Socket";

export enum Topics {
    UPDATE,
    START,
    CLASS_HISTORIES,
    COMMIT_HISTORIES,
    EVOLUTION_MATRIX,
    EVOLUTION_MATRIX_VERSIONED_START,
    EVOLUTION_MATRIX_VERSIONED_UPDATE
}

interface CallbackObjectInterface {
    callback: Function,
    obj: Object
}

export class TopicHandler {

    private topicUrlMap: Map<Topics, Map<SocketReqType, string>> = new Map<Topics, Map<SocketReqType, string>>();
    private topicCallbackMap: Map<Topics, CallbackObjectInterface> = new Map<Topics, CallbackObjectInterface>();

    constructor() {
        let t1 = new Map<SocketReqType, string>();
        t1.set(SocketReqType.SUBSCRIBE, "/user/queue/history/class/start");
        t1.set(SocketReqType.PUBLISH, "/app/history/class/start");
        this.topicUrlMap.set(Topics.CLASS_HISTORIES, t1);

        let t2 = new Map<SocketReqType, string>();
        t2.set(SocketReqType.SUBSCRIBE, "/user/queue/history/commit/start");
        t2.set(SocketReqType.PUBLISH, "/app/history/commit/start");
        this.topicUrlMap.set(Topics.COMMIT_HISTORIES, t2);

        let t3 = new Map<SocketReqType, string>();
        t3.set(SocketReqType.SUBSCRIBE, "/user/queue/history/evomatrix/start");
        t3.set(SocketReqType.PUBLISH, "/app/history/evomatrix/start");
        this.topicUrlMap.set(Topics.EVOLUTION_MATRIX, t3);

        let t4 = new Map<SocketReqType, string>();
        t4.set(SocketReqType.SUBSCRIBE, "/user/queue/history/evomatrix/versioned/start");
        t4.set(SocketReqType.PUBLISH, "/app/history/evomatrix/versioned/start");
        this.topicUrlMap.set(Topics.EVOLUTION_MATRIX_VERSIONED_START, t4);

        let t5 = new Map<SocketReqType, string>();
        t5.set(SocketReqType.SUBSCRIBE, "/user/queue/history/evomatrix/versioned/update");
        t5.set(SocketReqType.PUBLISH, "/app/history/evomatrix/versioned/update");
        this.topicUrlMap.set(Topics.EVOLUTION_MATRIX_VERSIONED_UPDATE, t5);

    }


    public getEndpoint(socketReqType: SocketReqType, topic: Topics): string {
        if (typeof this.topicUrlMap.get(topic) != "undefined") {
            if (this.topicUrlMap.get(topic) != undefined) {
                // @ts-ignore
                return <string>this.topicUrlMap.get(topic).get(socketReqType);
            }
            return ""
        } else {
            return "";
        }
    }

    public registerCallback(topic: Topics, callback: Function, obj: Object) {
        this.topicCallbackMap.set(topic, {callback: callback, obj: obj});
    }

    public getCallback(topic: Topics) {
        return this.topicCallbackMap.get(topic);
    }
}
