<template>
    <div class="playpause justify-content-center align-items-center">
        <div class="player-info">{{version}}/{{versions}} {{1/(speed)}}x</div>
        <div aria-label="Basic example" class="btn-group player-btn-group" role="group" >
            <button @click="stepBackward()" class="btn skip">
                <font-awesome-icon :icon="['fas', 'step-backward']" size="xs"/>
            </button>
            <button @click="halfSpeed()" class="btn skip">
                <font-awesome-icon :icon="['fas', 'fast-backward']" size="xs"/>
            </button>
            <button @click="isPlaying = true, play()" class="btn btn-circle" v-if="!isPlaying || ended">
                <font-awesome-icon :icon="['fas', 'play']" size="xs" />
            </button>

            <button @click="isPlaying = false, pause()" class="btn btn-circle" v-else>
                <font-awesome-icon :icon="['fas', 'pause']" size="xs"/>
            </button>

            <button @click="isPlaying = false, stop()" class="btn btn-circle" v-bind:class="(isPlaying)?'active':''">
                <font-awesome-icon :icon="['fas', 'stop']" size="xs"/>
            </button>

            <button @click="doubleSpeed()" class="btn skip">
                <font-awesome-icon :icon="['fas', 'fast-forward']" size="xs"/>
            </button>
            <button @click="stepForward()" class="btn skip">
                <font-awesome-icon :icon="['fas', 'step-forward']" size="xs"/>
            </button>
        </div>
<!--        /z-->
    </div>
</template>

<script>
    import store from '../../store';
    import CommitHashSearch from "./CommitHashSearch";

    export default {
        name: "Player",
        components: {CommitHashSearch},
        props: ['method', 'color'],
        data() {
            return {
                isPlaying: this.$store.getters['view/getIsPlaying'],
                view: this.$store.getters['view/getCurrentView'],
                speed: this.$store.getters['view/getEvolutionSpeed'],
                version: store.getters['view/markLineIndex'],
                versions: this.$store.getters['view/getEvolutionVersions'],
                ended: false,
                isRecording: store.getters['settings/isRecording']
            }
        },
        computed: {
            getIsRecording() {
                return this.$store.getters['settings/isRecording']
            },
            getView() {
                return this.$store.getters['view/getCurrentView']
            },
            getSpeed() {
                return this.$store.getters['view/getEvolutionSpeed']
            },
            getVersion() {
                return store.getters['view/markLineIndex']
            },
            getVersions() {
                return this.$store.getters['view/getEvolutionVersions']
            },
            getIsPlaying() {
                return this.$store.getters['view/getIsPlaying']
            }
        },
        watch: {
            getIsRecording(change){
                this.isRecording = change;
            },
            getView(change) {
                this.view = change;
            },
            getSpeed(change) {
                this.speed = change;
            },
            getVersion(change) {
                this.version = change;
                this.ended = this.isFinish()
            },
            getVersions(change) {
                this.versions = change;
            },
            getIsPlaying(change) {
                this.isPlaying = change;
            },
        },
        methods: {
            play() {
                this.view.play();
            },
            pause() {
                this.view.pause()
            },
            stop() {
                this.view.stop();
            },
            doubleSpeed() {
                store.commit('view/evolutionSpeed', this.speed / 2);
            },
            halfSpeed() {
                store.commit('view/evolutionSpeed', this.speed * 2);
            },
            stepForward(){
                this.view.nextVersion();
            },
            stepBackward(){
                this.view.previousVersion();
            },
            isFinish() {
                // if (this.versions === this.version ) {
                //     // this.isPlaying = false;
                //     // this.pause();
                //     // store.commit('view/isPlaying', false );
                //     // store.commit('view/evolutionVersion', 0 );
                //     return true
                // }
                return false;
            }
        }
    }
</script>

<style scoped>
    button {
        background-color: transparent;
        color: white;
        border-radius: 8px;
        padding: 5px;
        font-size: xx-large !important;
    }
    button:focus {outline:0 !important;}

    button:hover {
        color: cornflowerblue;
    }

    .skip {
        padding-left: 5px;
        padding-right: 5px;
    }

    .active {
        color: crimson;
    }

    .player-info {
        font-size: x-small;
        margin-top: 12px;
    }

    .player-btn-group {
        top: -12px;
    }

    button{
        color: black;
    }


</style>
