<template>
    <div class="overlay" v-if="commit && isVisible">
        <div class="" style="padding-right: 0px !important;  padding: 10px;
        background-color: rgba(255, 255, 255, 0.45);
        border-radius: 5px;">
        <div class="title">
            {{commit['commitShortMessage']}}
        </div>
        </div>
    </div>
</template>

<script>
    import store from '../../../store'
    export default {
        name: "CanvasCommitMessage",
        props: ['commit'],
        data() {
            return {
                isVisible: store.getters['settings/commitMessage'],
            }
        },
        computed:{
            getCommitMessageSettings(){
                return store.getters['settings/commitMessage']
            }
        },
        watch:{
            getCommitMessageSettings(change){
                this.isVisible = change;
            }
        }
    }
</script>

<style scoped>

    .overlay {
        margin-bottom: 5px;
        position: absolute;
        bottom: 0;
        right: 0;
        color: black;
        padding: 5px;
        pointer-events: none;
        max-width: 33.333%;

    }

    .overlay > div > div.title {
        font-size:1.55rem;
        word-wrap: break-word !important;
    }

    span.connection {
        font-size: 2rem;
    }

</style>
