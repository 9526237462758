import {ActionTree} from 'vuex';
import {Repository, RepositoryState} from './types';
import {RootState} from '../types';
import {getRepositoryData} from "../../api";


export const actions: ActionTree<RepositoryState, RootState> = {
    fetchData({commit}, repoId: string): Promise<void> {
        return new Promise((resolve, reject) => {
            getRepositoryData(repoId).then((response) => {
                const payload: Repository = response;
                commit('repositoryLoaded', payload);
                resolve()
            }, (error) => {
                commit('repositoryError');
                reject()
            });
        })
    },
};
