import {ActionTree} from 'vuex';
import {RootState} from '../types';
import {M3ClassState} from "./types";
import {View} from "../../view/View";
import store from "../index";
import {Color3, Color4} from "@babylonjs/core";


export const actions: ActionTree<M3ClassState, RootState> = {
    async classLoaded({commit, state}): Promise<void> {
        await store.dispatch("timeline/getHighlightPoints")
        if(state.mesh) {
            state.mesh.edgesColor = state.highlightColor
            state.mesh.enableEdgesRendering();
            state.mesh.edgesWidth = Math.log(state.mesh.scaling.x * state.mesh.scaling.y) * 10
        }
    },
    async resetState({commit, state}): Promise<void> {
        if(state.mesh) {
            state.mesh.disableEdgesRendering()
        }
        state.m3Class = undefined
        state.mesh = undefined
        state.selected = false
        await store.dispatch("timeline/resetState")
    }
}
