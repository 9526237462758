import {
    AbstractMesh,
    Animation,
    ArcRotateCamera,
    Color3,
    CubicEase,
    Curve3,
    EasingFunction,
    InstancedMesh, LinesMesh,
    Mesh,
    MeshBuilder,
    Vector3,
    VertexBuffer
} from "@babylonjs/core";
import store from '../../store';
import {v4 as uuidv4} from 'uuid';

import uuid from "uuid";


export const setScalingAnimationY = function (value: number | undefined, mesh: InstancedMesh | AbstractMesh, deleted?: boolean) {
    let frameRate = 10;
    let animationDuration = store.getters['view/getEvolutionSpeed'];
    var animationBox = new Animation("scalYAn", "scaling.y", frameRate, Animation.ANIMATIONTYPE_FLOAT, Animation.ANIMATIONLOOPMODE_CONSTANT);
    var keys = [];

    keys.push({
        frame: 0,
        value: mesh.scaling.y
    });
    if (!deleted) {

        keys.push({
            frame: animationDuration * frameRate,
            value: value
        });

    } else {
        keys.push({
            frame: animationDuration * frameRate,
            value: 0
        });
    }

    animationBox.setKeys(keys);
    // mesh.animations = [];
    mesh.animations.push(animationBox);

    let animatable = mesh.getScene().beginAnimation(mesh, 0, animationDuration * frameRate, false);
    animatable.onAnimationEnd = function () {
        animatable.animationStarted = false;
    };
    animatable.disposeOnEnd = true;
};

export const setScalingAnimationXYZ = function (width: number | undefined, depth: number | undefined, height: number | undefined, mesh: Mesh, deleted?: boolean) {
    let frameRate = 10;
    let animationDuration = store.getters['view/getEvolutionSpeed'];
    var animationBoxZ = new Animation("scalZAn", "scaling.z", frameRate, Animation.ANIMATIONTYPE_FLOAT, Animation.ANIMATIONLOOPMODE_CONSTANT);
    var animationBoxX = new Animation("scalXAn", "scaling.x", frameRate, Animation.ANIMATIONTYPE_FLOAT, Animation.ANIMATIONLOOPMODE_CONSTANT);
    var animationBoxY = new Animation("scalYAn", "scaling.y", frameRate, Animation.ANIMATIONTYPE_FLOAT, Animation.ANIMATIONLOOPMODE_CONSTANT);

    var keysWidth = [];

    keysWidth.push({
        frame: 0,
        value: mesh.scaling.x
    });
    if (!deleted) {
        keysWidth.push({
            frame: animationDuration * frameRate,
            value: width
        });
    } else {
        keysWidth.push({
            frame: animationDuration * frameRate,
            value: 0
        });
    }

    var keysDepth = [];

    keysDepth.push({
        frame: 0,
        value: mesh.scaling.z
    });

    if (!deleted) {
        keysDepth.push({
            frame: animationDuration * frameRate,
            value: depth
        });
    } else {
        keysDepth.push({
            frame: animationDuration * frameRate,
            value: 0
        });
    }

    var keysHeight = []
    keysHeight.push({
        frame: 0,
        value: mesh.scaling.y
    });
    if (!deleted) {

        keysHeight.push({
            frame: animationDuration * frameRate,
            value: height
        });

    } else {
        keysHeight.push({
            frame: animationDuration * frameRate,
            value: 0
        });
    }

    animationBoxX.setKeys(keysWidth);
    animationBoxZ.setKeys(keysDepth);
    animationBoxY.setKeys(keysHeight)
    // mesh.animations = [];
    mesh.animations.push(animationBoxX);
    mesh.animations.push(animationBoxZ);
    mesh.animations.push(animationBoxY)

    let animatable = mesh.getScene().beginAnimation(mesh, 0, animationDuration * frameRate, false);
    animatable.onAnimationEnd = function () {
        animatable.animationStarted = false;
        if (deleted) {
            mesh.dispose()
        }
    };
    animatable.disposeOnEnd = true;


};

let lineColor = new Color3(1, 1, 0.5);

function drawMoveLineInScene(mesh: InstancedMesh | AbstractMesh, value: Vector3) {
    const quadraticBezierVectorsDraw = Curve3.CreateQuadraticBezier(
        mesh.position.add(new Vector3(mesh.scaling.x / 2, mesh.scaling.y / 2, mesh.scaling.z / 2)),
        mesh.position.add(new Vector3(0, 200 + (value.y), (0))),
        value.add(new Vector3(mesh.scaling.x / 2, mesh.scaling.y, mesh.scaling.z / 2)),
        60);

    let bazLine = MeshBuilder.CreateLines("baz_"+ uuidv4(), {
        points: quadraticBezierVectorsDraw.getPoints(),
        updatable: false
    })
    bazLine.alpha = 0.5
    bazLine.freezeNormals();
    bazLine.freezeWorldMatrix();
    bazLine.color = lineColor;

    store.commit("view/evolutionAddRenameLines", bazLine)

}

export const setPositionAnimation = function (value: Vector3, mesh: Mesh) {
    drawMoveLineInScene(mesh, value);
    mesh.position = value

    // let frameRate = 10;
    // let animationDuration = store.getters['view/getEvolutionSpeed'];
    // const quadraticBezierVectorsAnimation = Curve3.CreateQuadraticBezier(
    //     mesh.position,
    //         mesh.position.add(new Vector3(0, Math.sqrt(((value.x - mesh.position.x)^2)+(value.z + mesh.position.z)^2) + (value.y), (0))),
    //     value,
    //     5);
    //
    // var path = quadraticBezierVectorsAnimation.getPoints(); //[{x:0, y:0, z: 0}, {x:10, y:00, z: 10}, {x:20, y:20, z: 20}, {x:30, y:30, z: 30}, {x:40, y:0, z: 35}];
    //
    //
    // var animationPosition = new Animation("animPos", "position", frameRate, Animation.ANIMATIONTYPE_VECTOR3, Animation.ANIMATIONLOOPMODE_CONSTANT);
    //
    // var keysPosition = [];
    //
    // for (let p = 0; p < path.length; p++) {
    //     keysPosition.push({
    //         frame: p,
    //         value: path[p]
    //     });
    // }
    //
    // animationPosition.setKeys(keysPosition);
    // mesh.animations.push(animationPosition);
    // let animatable = mesh.getScene().beginAnimation(mesh, 0, animationDuration * frameRate, false);
    // // animatable.onAnimationEnd = function () {
    // //     animatable.animationStarted = false;
    // // };
    // animatable.disposeOnEnd = true;
};

const zoom = function (abMesh: AbstractMesh, targetVector: Vector3, speed: number) {
    let ease = new CubicEase();
    ease.setEasingMode(EasingFunction.EASINGMODE_EASEINOUT);
    let an = Animation.CreateAndStartAnimation('zoomCamera', abMesh, 'position', speed, 120, abMesh.position, targetVector, 0, ease);
    if (an != null) an.disposeOnEnd = true;
}

export function moveTo(abMesh: AbstractMesh, targetVector: Vector3, speed: number) {
    let ease = new CubicEase();
    ease.setEasingMode(EasingFunction.EASINGMODE_EASEINOUT);
    let an = Animation.CreateAndStartAnimation('moveCamera', abMesh, 'position', speed, 120, abMesh.position, targetVector, 0, ease);
    if (an != null) an.disposeOnEnd = true;
}

export function moveCameraToPoint(camera: ArcRotateCamera, to: Vector3, radius?: number) {
    if (camera != null && to != null) {
        moveTo(camera.lockedTarget, to, 70);
        if (radius != null) {
            // camera.upperRadiusLimit = radius * 4;
            let radiusAnimation = new Animation("camRadius", "radius", 30, Animation.ANIMATIONTYPE_FLOAT, Animation.ANIMATIONLOOPMODE_CONSTANT);
            var keys1 = [{
                frame: 0,
                value: camera.radius
            }, {
                frame: 100,
                value: radius
            }];
            radiusAnimation.setKeys(keys1);
            camera.animations.push(radiusAnimation);
            camera.getScene().beginAnimation(camera, 0, 100, false, 1);
        }
    }
}

function colorVertices(mesh: Mesh, color: Color3) {

    var positions = mesh.getVerticesData(VertexBuffer.PositionKind);

    var colors = [];

    if (positions) {
        for (var i = 0; i < positions.length; i += 3) {
            colors.push(color.r);
            colors.push(color.g);
            colors.push(color.b);
            colors.push(1);
        }

        mesh.setVerticesData(VertexBuffer.ColorKind, colors);
    }
}
