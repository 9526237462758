import Vue from "vue";
import {BootstrapVue, BootstrapVueIcons, IconsPlugin} from 'bootstrap-vue'
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap-vue/dist/bootstrap-vue.css"
import App from './App.vue';
import store from './store'
// @ts-ignore
import ECharts from 'vue-echarts'
import VueApexCharts from 'vue-apexcharts'

import router from './router'
import { library } from '@fortawesome/fontawesome-svg-core'
import {
    faUserSecret,
    faPlay,
    faStop,
    faFastForward,
    faFastBackward,
    faStepForward, faStepBackward, faPause, faCogs, faPlus, faCompress, faRecordVinyl
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(faPlay, faStop, faFastForward, faFastBackward, faStepForward, faStepBackward, faPause, faCogs, faPlus, faCompress, faRecordVinyl)
Vue.component('v-chart', ECharts)
Vue.component('apexchart', VueApexCharts)

Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(IconsPlugin);
const app = new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount('#app');

