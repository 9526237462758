<template>
    <div class="overlay">
        <span>{{this.currentCanvasObjects()}} objects</span>
    </div>
</template>

<script>
    import {mapGetters} from "vuex";

    export default {
        name: "CanvasObjects",
        methods: {
            ...mapGetters('canvasObjects', ['currentCanvasObjects']),
        }
    }
</script>

<style scoped>

    .overlay {
        position: absolute;
        left: 0;
        bottom: 0;
        right: 0;
        color: white;
        pointer-events: none;
        padding: 5px;
    }

    .overlay > span {
        padding: 4px;
        font-style: italic;
        background-color: rgba(0, 0, 0, 0.6);
        border-radius: 2px;
    }

</style>
