import {CubeConfig, Cuboid, CustomMesh} from "./Cuboid";
import {Color3, Mesh, Scene, StandardMaterial, Vector3} from "@babylonjs/core";
import {setPositionAnimation, setScalingAnimationXYZ} from "./utils/animation";
import addCuboidClickAction from "./utils/action";
import store from "../store";
import {AdvancedDynamicTexture} from "@babylonjs/gui";
import {EvolutionMatrixVersionedView} from "../view/EvolutionMatrixVersionedView";
import {
    ALL,
    CHANGES_ONLY,
    CLASS_ONLY,
    REGEX,
    NONE,
    PACKAGE_ONLY,
    FILENAME,
    AUTHORNAME,
    FILENAMEAUTHORNAME, TAGS, RECORD
} from "../view/options/SettingsModes";
import {View} from "../view/View";
import {Text} from './Text'

export class M3Class extends Cuboid {

    constructor(scene: Scene, adt: AdvancedDynamicTexture) {
        super(scene, adt);
    }

    async drawClone(config: CubeConfig, callback: Function, evolutionMatrixVersionedView?: EvolutionMatrixVersionedView) {
        let clone = this.getClone(config);
        addCuboidClickAction(clone, callback);
        // console.log(config.intensity, config.entityType)

        this.setDefaultMaterial(clone, config);
        if (config.deleted) clone.dispose();
        M3Class.displayLabelPopup(evolutionMatrixVersionedView, config, clone);
        M3Class.displayElision(evolutionMatrixVersionedView, config, clone)
        clone.repositoryVersion = config.repositoryVersion
        clone.nameString = config.nameString
        clone.authorString = config.authorString
        clone.tags = config.tags;
        clone.alpha = config.alpha;
        clone.intensity = config.intensity
        clone.entityType = config.entityType
        clone.config = config


        return clone;
    }

    // async cloneToInstance(clone: Mesh) {
    //     let instance = this.getInstance({
    //         depth: clone.scaling.z,
    //         height: clone.scaling.y,
    //         id: clone.id,
    //         name: clone.name,
    //         x: clone.position.x, y: clone.position.y, z: clone.position.z,
    //         scene: clone.getScene(),
    //         width: clone.scaling.x
    //     });
    //     instance.actionManager = clone.actionManager;
    //     clone.dispose(true, true);
    //     return instance
    // }
    //
    async instanceToClone(instance: CustomMesh) {
        let clone = this.getClone({
            depth: instance.scaling.z,
            height: instance.scaling.y,
            id: instance.id,
            name: instance.name,
            x: instance.position.x, y: instance.position.y, z: instance.position.z,
            scene: instance.getScene(),
            width: instance.scaling.x,
            nameString : instance.nameString,
            authorString  : instance.authorString,
            repositoryVersion: instance.repositoryVersion,
        });
        clone.scaling = new Vector3(instance.scaling.x, instance.scaling.y, instance.scaling.z)
        clone.actionManager = instance.actionManager;
        instance.dispose(true, false);
        clone.material = instance.material

        return clone
    }

    async update(mesh: Mesh, value: any, evolutionMatrixVersionedView?: EvolutionMatrixVersionedView) {
        let clone = await this.instanceToClone(mesh);
        // let clone = mesh;
        clone.id = value.id;
        clone.name = value.name;
        if (clone.position.x != value.x || clone.position.z != value.z || clone.position.y != value.y) {
            setPositionAnimation(new Vector3(value.x, value.y, value.z), clone)
        }
        // setScalingAnimationY(value.height, clone, value.deleted);
        // console.log(value)
        setScalingAnimationXYZ(value.width, value.depth, value.height, clone, value.deleted);
        this.setDefaultMaterial(clone, value);
        // if()
        // this.setDefaultMaterial(clone, value);
        if (value.deleted) clone.dispose();
        // console.log(evolutionMatrixVersionedView)
        M3Class.displayLabelPopup(evolutionMatrixVersionedView, value, clone);
        M3Class.displayElision(evolutionMatrixVersionedView, value, clone)

        clone.repositoryVersion = value.repositoryVersion
        clone.nameString = value.nameString
        clone.authorString = value.authorString
        clone.tags = value.tags;
        clone.alpha = value.alpha;
        clone.intensity = value.intensity
        clone.entityType = value.entityType
        clone.config = value


        return clone;
    }

    static  displayLabelPopup(evolutionMatrixVersionedView: EvolutionMatrixVersionedView | undefined, value: any, clone:any) {
        if (evolutionMatrixVersionedView && !value.deleted && !value.placeholder) {
            let nameMode = store.getters['settings/nameMode'];
            let nameText = store.getters['settings/nameText']
            let finalText = ""
            switch (nameText) {
                case FILENAME:
                    finalText = value.nameString.split('/').pop()
                    break;
                case AUTHORNAME:
                    finalText = value.authorString.substring(0,value.authorString.lastIndexOf("@"))
                    break;
                case FILENAMEAUTHORNAME:
                    finalText = value.nameString.split('/').pop()  + " " +  value.authorString.substring(0,value.authorString.lastIndexOf("@"))
                    break;
            }
            // console.log(nameMode, nameText, finalText)

            // console.log('final',finalText, nameText, value, value.authorString.lastIndexOf("@"))
            if (nameMode == ALL) {
                let text = Cuboid.makeText(clone, evolutionMatrixVersionedView.adt, finalText);
                if (text != null) {
                    evolutionMatrixVersionedView.ovTexts.push(text)
                }
            } else if (nameMode == CHANGES_ONLY) {
                let text = Cuboid.makeText(clone, evolutionMatrixVersionedView.adt, finalText);
                if (text != null) {
                    evolutionMatrixVersionedView.ovTexts.push(text)
                }

            } else if (nameMode == PACKAGE_ONLY && value.entityType === 'package') {
                let text = Cuboid.makeText(clone, evolutionMatrixVersionedView.adt, finalText);
                if (text != null) {
                    evolutionMatrixVersionedView.ovTexts.push(text)
                }
            } else if (nameMode == CLASS_ONLY && value.entityType === 'class') {
                let text = Cuboid.makeText(clone, evolutionMatrixVersionedView.adt, finalText);
                if (text != null) {
                    evolutionMatrixVersionedView.ovTexts.push(text)
                }
            }else if(nameMode == REGEX){
                var term = value.nameString;
                var re = new RegExp(store.getters['settings/nameRegex']);
                if (re.test(term)) {
                    // console.log('valid', store.getters['settings/nameRegex'])
                    let text = Cuboid.makeText(clone, evolutionMatrixVersionedView.adt, finalText);
                    if (text != null) {
                        evolutionMatrixVersionedView.ovTexts.push(text)
                    }
                }
            }else if (nameMode == NONE){}
        }
    }


    static updateLabelPopup( value: any) {
        if (!value.deleted && !value.placeholder) {
            let nameMode = store.getters['settings/nameMode'];
            let nameText = store.getters['settings/nameText']
            let finalText = ""
            switch (nameText) {
                case FILENAME:
                    finalText = value.nameString.split('/').pop()
                    break;
                case AUTHORNAME:
                    finalText = value.authorString.substring(0,value.authorString.lastIndexOf("@"))
                    break;
                case FILENAMEAUTHORNAME:
                    finalText = value.nameString.split('/').pop()  + " " +  value.authorString.substring(0,value.authorString.lastIndexOf("@"))
                    break;
            }
            return finalText
        }
    }


    setDefaultMaterial(mesh: Mesh, value:any): void {

        let oldMat = mesh.material;
        let blueMat = null;
        let intensity = value.intensity;
        let alpha = value.alpha;

        var name = value.nameString;
        var author = value.authorString;
        var re = new RegExp(store.getters['settings/colorizationRegex'].regex);
        let flag = false;
        if (re.test(name) || re.test(author)) {
            flag = true;
        }


        if(store.getters['settings/colorizationMode'] == TAGS && M3Class.containsTag(mesh,value)){
            let color = M3Class.getTagColor(value);

            let mat = mesh.getScene().getMaterialByName(color.toString())
            if(mat == null){
                mat = new StandardMaterial(color.toString(), mesh.getScene());
                mat.freeze();

            }
            let rgb =   {r:color.r, g:color.g, b:color.b}

            // console.log(rgb)
            // @ts-ignore
            mat.diffuseColor = new Color3(rgb.r, rgb.g, rgb.b)
            // @ts-ignore
            mat.specularColor = new Color3(rgb.r, rgb.g, rgb.b);
            mat.backFaceCulling = true;

            // @ts-ignore
            // blueMat.hasAlpha = false;
            mesh.material = mat

        }else if(store.getters['settings/colorizationMode'] == REGEX && flag){
            let color = store.getters['settings/colorizationRegexColor'];
            let mat = mesh.getScene().getMaterialByName(color.toString())
            if(mat == null){
                mat = new StandardMaterial(color.toString(), mesh.getScene());
                mat.freeze();

            }
            // @ts-ignore
            mat.diffuseColor = color
            // @ts-ignore
            mat.specularColor =  color
            mat.backFaceCulling = true;

            // @ts-ignore
            // blueMat.hasAlpha = false;
            mesh.material = mat
        }else  if(store.getters['settings/colorizationMode'] == RECORD && M3Class.isRecordMesh(mesh)){

            let color = M3Class.getRecordColor(mesh)
            // @ts-ignore
            let mat = mesh.getScene().getMaterialByName(color.toString())
            if(mat == null){
                // @ts-ignore
                mat = new StandardMaterial(color.toString(), mesh.getScene());
                mat.freeze();

            }
            // @ts-ignore
            mat.diffuseColor = color
            // @ts-ignore
            mat.specularColor =  color
            mat.backFaceCulling = true;

            // @ts-ignore
            // blueMat.hasAlpha = false;
            mesh.material = mat
        }
        else {

            if (intensity) {
                blueMat = mesh.getScene().getMaterialByName("blue" + intensity);
            } else {
                blueMat = mesh.getScene().getMaterialByName("blue");
            }
            if (blueMat != null) {
                mesh.material = blueMat
            } else {
                if (intensity) {
                    blueMat = new StandardMaterial("blue" + intensity, mesh.getScene());
                    blueMat.diffuseColor = new Color3(0, 0, 1)

                } else {
                    blueMat = new StandardMaterial("blue", mesh.getScene());
                    blueMat.diffuseColor = new Color3(0, 0, 1)

                }

                // blueMat.emissiveColor = new Color3(0, 0, 1);
                blueMat.specularColor = new Color3(0, 0, 0);
                blueMat.backFaceCulling = true;

                // @ts-ignore
                // blueMat.hasAlpha = false;
                blueMat.freeze();
                mesh.material = blueMat
            }
        }
    }

    static displayElision(evolutionMatrixVersionedView: EvolutionMatrixVersionedView| undefined, value: any, clone: any) {
        if (evolutionMatrixVersionedView) {
            let nameMode = store.getters['settings/elisionMode']
            if (nameMode == ALL) {

                Cuboid.hide(clone)
                evolutionMatrixVersionedView.elisionMeshes.push(clone)

            } else if (nameMode == CHANGES_ONLY) {
                Cuboid.hide(clone)
                // if (text != null) {
                //     evolutionMatrixVersionedView.ovTexts.push(text)
                // }
                evolutionMatrixVersionedView.elisionMeshes.push(clone)
            } else if (nameMode == PACKAGE_ONLY && value.entityType === 'package') {
                Cuboid.hide(clone)
                // if (text != null) {
                //     evolutionMatrixVersionedView.ovTexts.push(text)
                // }
                evolutionMatrixVersionedView.elisionMeshes.push(clone)
            } else if (nameMode == CLASS_ONLY && value.entityType === 'class') {
                Cuboid.hide(clone)
                // if (text != null) {
                //     evolutionMatrixVersionedView.ovTexts.push(text)
                // }
                evolutionMatrixVersionedView.elisionMeshes.push(clone)
            } else if (nameMode == REGEX) {
                var term = value.nameString;
                var re = new RegExp(store.getters['settings/elisionRegex']);
                if (re.test(term)) {
                    // console.log('valid', store.getters['settings/elisionRegex'])
                    Cuboid.hide(clone)
                }
                evolutionMatrixVersionedView.elisionMeshes.push(clone)
            }else if (nameMode == NONE){Cuboid.show(clone)}
        }
    }

    static containsTag(mesh:Mesh, value:any){
        let tags = value.tags;
        let selectedTags = store.getters['settings/colorizationTags']
        // console.log(tags,selectedTags)
        for (let i = 0; i < selectedTags.length; i++) {
            for (let j = 0; j < tags.length; j++) {
                if(selectedTags[i]!=null && tags[j] == selectedTags[i].tag){
                    return true
                }
            }
        }
        return false
    }

    static isRecordMesh(mesh:Mesh){
        let recordedMeshes = store.getters['settings/recordedMeshes']
        for (let i = 0; i < recordedMeshes.length; i++) {
            let customMesh:{mesh:CustomMesh, color:Color3} = recordedMeshes[i]
            if(customMesh!= null && customMesh.mesh.id == mesh.id){
                return true;
            }
        }

        return false
    }

    static getRecordColor(mesh:Mesh){
        let recordedMeshes = store.getters['settings/recordedMeshes']
        for (let i = 0; i < recordedMeshes.length; i++) {
            let customMesh:{mesh:CustomMesh, color:Color3} = recordedMeshes[i]
            if(customMesh.mesh.id == mesh.id){
                return customMesh.color;
            }
        }
    }

    static getTagColor(value:any){
        let tags = value.tags;
        let selectedTags = store.getters['settings/colorizationTags']
        let colors = []
        for (let i = 0; i < selectedTags.length; i++) {
            for (let j = 0; j < tags.length; j++) {
                if(selectedTags[i]!= null && tags[j] == selectedTags[i].tag){
                    colors.push(selectedTags[i].color)
                }
            }
        }
        if(colors.length > 1) {
            let a = colors[0];
            for (let i = 1; i < colors.length; i++) {
                a = Color3.Lerp(a, colors[i], .5)
            }

            return a;
        }else {
            return colors[0]
        }
    }

    static staticSetMaterial(mesh:CustomMesh,color:Color3){

        let mat = mesh.getScene().getMaterialByName(color.toString())
        if(mat == null){
            mat = new StandardMaterial(color.toString(), mesh.getScene());
            mat.freeze();
        }
        // @ts-ignore
        mat.diffuseColor = color
        // @ts-ignore
        mat.specularColor =  color
        mat.backFaceCulling = true;

        // @ts-ignore
        // blueMat.hasAlpha = false;

        mesh.material = mat
    }

    static setBaseMaterial(mesh:CustomMesh) {
        let blueMat = null;
        if (mesh.config) {
            let intensity = mesh.config.intensity;
            let alpha = mesh.config.alpha;
            if (intensity) {
                blueMat = mesh.getScene().getMaterialByName("blue" + intensity);
            } else {
                blueMat = mesh.getScene().getMaterialByName("blue");
            }
            if (blueMat != null) {
                mesh.material = blueMat
            } else {

                if (intensity) {
                    blueMat = new StandardMaterial("blue" + intensity, mesh.getScene());
                    blueMat.diffuseColor = new Color3(0, 0, Math.log(intensity) * 1)

                } else {
                    blueMat = new StandardMaterial("blue", mesh.getScene());
                    blueMat.diffuseColor = new Color3(0, 0, 1)
                    blueMat.freeze();

                }

                // blueMat.emissiveColor = new Color3(0, 0, 1);
                blueMat.specularColor = new Color3(0, 0, 0);
                blueMat.backFaceCulling = true;

                // @ts-ignore
                // blueMat.hasAlpha = false;
                // blueMat.freeze();

                mesh.material = blueMat
            }
        }
    }
}
