<template>
    <div class="col-4">
        <div class="col-md-12">
            <h4>Artifact Settings</h4>
        </div>
        <div class="col-md-12">
            <div class="col-auto">
                <label class="my-1 mr-2" for="inlineFormCustomSelectPref">Depth Metric</label>
                <select class="custom-select my-1 mr-sm-2" id="inlineFormCustomSelectPref"
                        v-model.lazy="selected.view.depth">
                    <option v-for="(item,index) in supportedMetrics" :value="item.be" :selected="item.be == 'NOM'">
                        {{item.fe}}
                    </option>
                </select>
            </div>
            <div class="col-auto">
                <label class="my-1 mr-2" for="inlineFormCustomSelectPref1">Height Metric</label>
                <select class="custom-select my-1 mr-sm-2" id="inlineFormCustomSelectPref1"
                        v-model.lazy="selected.view.height">
                    <option selected v-for="item in supportedMetrics" :value="item.be"
                            :selected="item.be == 'DECL_STMT'">{{item.fe}}
                    </option>
                </select>
            </div>
        </div>
        <div class="col-12">
            <div class="row container">
                    <div class="col-4">
                        <div class="form-group">
                            <label for="bump">Bump</label>
                            <input type="number" min="1" class="form-control" id="bump" v-model.lazy="selected.view.bumpSize">
                            <small id="bumpHelp1" class="form-text text-muted">Distance between artifacts</small>
                        </div>
                    </div>
                    <div class="col-4">
                        <div class="form-group">
                            <label for="minWidth">Width</label>
                            <input type="number" min="1" class="form-control" id="minWidth" v-model.lazy="selected.view.minWidth" disabled>
                            <small id="minWidthHelp" class="form-text text-muted">Minimum width of the artifact</small>
                        </div>
                    </div>
                    <div class="col-4">
                        <div class="form-group">
                            <label for="minHeight">Height</label>
                            <input type="number" min="1" class="form-control" id="minHeight" v-model.lazy="selected.view.minHeight">
                            <small id="minHeightHelp" class="form-text text-muted">Minimum width of the artifact</small>
                        </div>
                    </div>
                </div>
        </div>
    </div>
</template>
<script>
    export default {
        name: 'ArtifactSettings',
        props: {
            fullScreen: {},
            selected: {},
            supportedMetrics: {}
        },
        methods:{}
    }
</script>
<style scoped>

    input {
        border: 1px solid transparent;
        background-color: #f1f1f1;
        padding: 10px;
        font-size: 16px;
    }

    input[type=text] {
        background-color: #f1f1f1;
        width: 100%;
    }

    input[type=submit] {
        background-color: DodgerBlue;
        color: #fff;
    }

    .autocomplete-items div {
        padding: 10px;
        cursor: pointer;
        background-color: #fff;
        border-bottom: 1px solid #d4d4d4;
    }

    .autocomplete-items div:hover {
        /*when hovering an item:*/
        background-color: #e9e9e9;
    }

</style>
<style>

    form {
        color: black !important;
    }

    .form-inline label {
        justify-content: left;
    }

</style>
