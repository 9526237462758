import {Repository} from "../store/m3-repository/types";
import axios from "axios";
import {variables} from "../variables";

let getRepositoryData = async (id: string): Promise<Repository> => {
    let response = await Promise.resolve(axios.get(variables.API_URL + '/api/repository/' + id));
    return response.data;
};

let getRepositoryTimeline = async (id: string): Promise<string> => {
    let response = await Promise.resolve(axios.get(variables.API_URL + '/api/repository/' + id + '/timeline/series'));
    return response.data;
};

export let getRepositoryTimelineDaily = async (id: string, mode:string): Promise<string> => {
    let response = await Promise.resolve(axios.get(variables.API_URL + '/api/repository/' + id + '/timeline/series/'+mode));
    return response.data;
};

let getRepositoryClassVersionTimelineHighlights = async (repoId: string, versionId: string): Promise<string> => {
    let response = await Promise.resolve(axios.get(variables.API_URL + '/api/repository/' + repoId + '/' + versionId + '/class/timeline/series'));
    return response.data;
};

let getRepositoryPackageVersionTimelineHighlights = async (repoId: string, versionId: string): Promise<string> => {
    let response = await Promise.resolve(axios.get(variables.API_URL + '/api/repository/' + repoId + '/' + versionId + '/package/timeline/series'));
    return response.data;
};

let getRepositoryClassVersionTimelineHighlightsMode = async (repoId: string, versionId: string, mode:string): Promise<string> => {
    let response = await Promise.resolve(axios.get(variables.API_URL + '/api/repository/' + repoId + '/' + versionId + '/class/timeline/series/'+mode ));
    return response.data;
};

let getRepositoryPackageVersionTimelineHighlightsMode = async (repoId: string, versionId: string, mode:string): Promise<string> => {
    let response = await Promise.resolve(axios.get(variables.API_URL + '/api/repository/' + repoId + '/' + versionId + '/package/timeline/series/' + mode));
    return response.data;
};

let getPackageVersionData = async (id: string): Promise<string> => {
    let response = await Promise.resolve(axios.get(variables.API_URL + '/api/package/version/' + id));
    return response.data;
};


let getM3PackageHistoryData = async (id: string): Promise<string> => {
    let response = await Promise.resolve(axios.get(variables.API_URL + '/api/package/history/' + id));
    return response.data;
};

let getHomeRepositoryListing = async (id: string): Promise<string> => {
    let response = await Promise.resolve(axios.get(variables.API_URL + '/api/home/listing/repositories'));
    return response.data;
};


let postNewAnalysis = async (url: string): Promise<String> => {
    let response = await Promise.resolve(axios.post(variables.API_URL + '/api/analyze', {
        url: url
    }));
    return response.statusText;
};

let getClassVersionData = async (id: string): Promise<string> => {
    let response = await Promise.resolve(axios.get(variables.API_URL + '/api/class/version/' + id));
    return response.data;
};



let getM3ClassHistoryData = async (id: string): Promise<string> => {
    let response = await Promise.resolve(axios.get(variables.API_URL + '/api/class/history/' + id));
    return response.data;
};

let getCanvasDateAuthTerminal = async (repoId: string, version:number ): Promise<string> => {
    let response = await Promise.resolve(axios.get(variables.API_URL + '/api/repository/'+repoId+'/'+version+'/terminal'))
    return response.data;
};

let getVersionFromCommitId = async (repoId: string, commidId:number ): Promise<string> => {
    let response = await Promise.resolve(axios.get(variables.API_URL + '/api/repository/'+repoId+'/'+commidId))
    return response.data;
};

let getRepositoryCommits = async (repoId: string, page:number ): Promise<string> => {
    let response = await Promise.resolve(axios.get(variables.API_URL + '/api/repository/'+repoId+'/commits/'+page))
    return response.data;
};

// @ts-ignore
export {
    getRepositoryTimeline,
    getRepositoryData,
    getPackageVersionData,
    getM3PackageHistoryData,
    getHomeRepositoryListing,
    postNewAnalysis,
    getClassVersionData,
    getRepositoryClassVersionTimelineHighlights,
    getRepositoryPackageVersionTimelineHighlights,
    getRepositoryClassVersionTimelineHighlightsMode,
    getRepositoryPackageVersionTimelineHighlightsMode,
    getM3ClassHistoryData,
    getCanvasDateAuthTerminal,
    getVersionFromCommitId,
    getRepositoryCommits
}
