<template>

    <div id="timeline">
        <IEcharts id="echarts"
                :option="scatter"
                @ready="onReady"
                @click="onClick"
                :resizable="true"
        />
    </div>
</template>

<script>
    import IEcharts from 'vue-echarts-v3/src/full.js';
    // import 'echarts/lib/chart/scatter'
    // import 'echarts/lib/component/polar'
    import store from "../../store"
    import {getRepositoryTimeline,getRepositoryTimelineDaily} from "../../api";
    import {Topics} from "../../Topic";
    import {DECL_STMT, FOR, LOC, NOM} from "../../view/options/SupportedMetrics";

    export default {
        name: 'TimelineDateBased',
        components: {
            IEcharts
        },
        props: {},
        data: () => ({
            loading: true,
            version: 0,

            instance:null,
            scatter: {
                textStyle: {
                    color: '#ffff'
                },
                tooltip: {
                    trigger: 'axis',
                    formatter: function (params) {
                        params = params[0].data[0];
                        var date = new Date(params);
                        return date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear();
                    },
                    axisPointer: {
                        animation: false,
                        label:{
                            show:true,
                            fontStyle:'oblique'
                        },
                    },
                    showContent:false,

                },
                grid: {
                    bottom: 0,
                    top:0,
                    containLabel: true
                },
                xAxis: [{
                    type: 'time',
                    data: [],
                    axisLine: {
                        show: false
                    },
                    splitLine: {
                        show:false
                    },
                    boundaryGap:false,
                }],
                yAxis: {
                    type:'category',
                    boundaryGap: [0, '100%'],
                    axisLine: {
                        show: false
                    },
                    splitLine: {
                        show:false
                    },
                    data: [],
                },
                series: [],
            },
            supportedMetrics: [LOC,NOM,DECL_STMT,FOR],
            dataMap: new Map()
        }),
        methods: {
            onReady(instance, ECharts) {
                this.setDataSeries()
                this.instance = instance
            },
            onClick(event, instance, ECharts) {
                let clickVersion = event.data[0]
                let newIndexMarkline =  this.scatter.xAxis.data.map(x=>x.toUTCString()).indexOf(clickVersion.toUTCString());
                let newIndexReq = store.getters['view/versionsList'][this.scatter.xAxis.data.map(x=>x.toUTCString()).indexOf(clickVersion.toUTCString())]
                store.commit("view/evolutionVersion", newIndexReq)
                store.commit("view/evolutionVersionNext", newIndexReq)
                store.commit("view/markLineIndex", newIndexMarkline)

                store.getters['view/getCurrentView'].nextVersion()

            },
            symbolSizeFunction(val) {
                let sizeValue = val[2] * 10
                let nOfVersions = Number(store.getters['view/getEvolutionVersions']);
                switch (true) {
                    case nOfVersions<50:
                        return sizeValue * 2.5
                    case nOfVersions<100:
                        return sizeValue * 2.5
                    case nOfVersions<500:
                        return sizeValue * 2.5
                    case nOfVersions<1000:
                        return sizeValue * 2.5
                    case nOfVersions>1000:
                        return sizeValue * 2.5
                }
                return sizeValue
            },
            setDataSeries() {
                getRepositoryTimelineDaily(this.$route.params.owner + '/' + this.$route.params.name, store.getters['view/playingMode']).then(data => {
                    let dataSeries = []
                    let yAxisSeries = [];
                    let xAxisSeries = []
                    for (let [key, value] of Object.entries(data)) {
                        let versionNumber = key;
                        let versionData = value;
                        xAxisSeries.push(new Date(versionNumber))
                        for (let [key1, value1] of Object.entries(versionData)) {
                            let stringKey1 = this.supportedMetrics.filter(m=>m.be === key1)[0].fe
                            let finalValue = value1 > 0 ? value1 : 0;
                            if (yAxisSeries.indexOf(stringKey1) >= 0) {
                                dataSeries.push([new Date(versionNumber), yAxisSeries.indexOf(stringKey1), finalValue])
                            } else {
                                yAxisSeries.push(stringKey1)
                                dataSeries.push([new Date(versionNumber), yAxisSeries.indexOf(stringKey1), finalValue])
                            }
                        }
                    }
                    let dates = xAxisSeries
                    dates.sort(function(a, b) {
                        // convert date object into number to resolve issue in typescript
                        return  +new Date(a) - +new Date(b);
                    })
                    this.scatter.series[0] = {
                        name: '',
                        type: 'scatter',
                        data: dataSeries,
                        symbolSize: this.symbolSizeFunction,

                        markLine: {
                            animation:false,
                            data: [ { name: 0, xAxis: dates[0] , label: {
                                    formatter: function(x){
                                        var date = new Date(x.data.xAxis);
                                        return date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear();
                                    },
                                    position: 0
                                } } ],
                            symbol:["circle","none"],
                            silent:true,
                            lineStyle:{
                                type:'solid',
                                color: "#76b1fa",
                            },
                            label:{
                                show:true,
                                fontStyle:'oblique'
                            },
                        },
                    }
                    this.scatter.xAxis.data = xAxisSeries
                    this.scatter.yAxis.data = yAxisSeries
                    this.loading = false;
                })
            }
        },
        computed:{
            getCurrentVersion(){
                return store.getters['view/getEvolutionVersion'];
            },
            getHighlightSeries(){
                return store.getters['timeline/getHighlightSeries']
            },
            playingMode(){
                return store.getters['view/playingMode']
            }
        },
        watch:{
            getCurrentVersion(change){
                this.version = change
                let dates = this.scatter.xAxis.data
                dates.sort(function(a, b) {
                    // convert date object into number to resolve issue in typescript
                    return  +new Date(a) - +new Date(b);
                })
                this.scatter.series[0].markLine.data[0] = { name: dates[store.getters['view/markLineIndex']], xAxis: dates[store.getters['view/markLineIndex']], label: {
                        formatter: function(x){
                            var date = new Date(x.data.xAxis);
                            return date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear();
                        },
                        position: 0,
                    } }
                this.instance.setOption(this.scatter)
            },
            getHighlightSeries(changes){
                let finalData = []
                let dates = this.scatter.xAxis.data
                dates.sort(function(a, b) {
                    // convert date object into number to resolve issue in typescript
                    return  +new Date(a) - +new Date(b);
                })
                for (let i = 0; i < dates.length; i++) {
                    let date = dates[i]
                    for (let j = 0; j < changes.length; j++) {
                        let change = changes[j];
                        if(change === i){
                            this.scatter.series[0].data.filter(x=>x[0].toUTCString() === date.toUTCString()).forEach(res => {
                                finalData.push(res)
                            })
                        }
                    }
                }
                this.scatter.series[1] =
                    {
                        type: 'scatter',
                        symbolSize: this.symbolSizeFunction,
                        data: finalData,
                        itemStyle:{
                            color: "#fffc55"
                        }
                    }
                this.instance.setOption(this.scatter)
            }
        },
        mounted() {
        }
    }
</script>

<style scoped>
    #timeline {
        width: 100%;
        height: 120px;
        margin-bottom: 5px;
    }
</style>
