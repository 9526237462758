<template>
    <div class="overlay" v-if="commit">

        <div class="col-sm-4 col-md-3" v-if="commit">
            <dl class="row">
                <dt class="col-sm-3">sha1</dt>
                <dd class="col-sm-9">{{commit['sha1'].substr(0,8)}}</dd>

                <dt class="col-sm-3">Time</dt>
                <dd class="col-sm-9">
                    {{new Date(commit['commitTime']).toUTCString()}}
                </dd>

                <dt class="col-sm-3">Author</dt>
                <dd class="col-sm-9">
                    {{commit['authorEmail']}}
                </dd>

                <dt class="col-sm-3">Message</dt>
                <dd class="col-sm-9" id="message">
                    {{commit['commitShortMessage']}}
                </dd>
            </dl>
        </div>
    </div>

</template>

<script>
    export default {
        name: "CanvasCommit",
        props: ['commit'],
        data() {
            return {
                date: new Date()
            }
        }
    }
</script>

<style scoped>

    .overlay {
        position: absolute;
        bottom: 0;
        left: 0;
        padding: 5px;
        pointer-events: none;
        color: black;
        font-size: .75rem;
        width: 100%;

    }

    #message{
       min-height: 30px;
        max-height: 60px;
        word-wrap: break-word;
    }

</style>
