<template>
    <div class="wrap">
        <canvas :ref="'v-' + title" class="c" ref="canvas" v-bind:id="'v-' + title"></canvas>
        <slot/>
    </div>
</template>

<script>
    import CanvasTitle from "./sub-components/CanvasTitle";
    import CanvasObjects from "./sub-components/CanvasObjects";
    import M3ClassData from "../shared/M3ClassData";
    import store from "../../store";
    import {View} from "../../view/View";
    import {Socket} from "../../Socket";

    function updateCanvas(){

    }
    export default {
        name: "ViewCanvas",
        components: {M3ClassData, CanvasObjects, CanvasTitle},
        data: function () {
            return {
                isVisible: true,
                repoName: null,
                repoOwner: null,
                updateFunction: null,
                settings:{
                    depth: store.getters['settings/depth'],
                    height: store.getters['settings/height']
                }
            }
        },
        props: ['title', 'socket', 'viewType'],
        computed:{
            getDepthMetric(){
                return store.getters['settings/depth'];
            },
            getHeightMetric(){
                return store.getters['settings/height'];
            },
        },
        watch:{
            async getDepthMetric(change){
                // this.socket.reset();
                // // let socket = new Socket();
                // socket  = await socket.connect();
                this.settings.depth = change;
                // await store.dispatch('view/reset')
                let oldView = store.getters['view/getCurrentView']
                oldView.depthMetric = change;
                await oldView.nextVersion();
                // let view = new this.viewType('v-' + this.title, this.$route.params.owner + '/' + this.$route.params.name, this.socket, this.$route.params.version, this.settings.depth, this.settings.height);
                // store.commit("view/setCurrentView", view);
                // view.animate();
            },
            async getHeightMetric(change){
                // this.socket.reset();
                // let socket = new Socket();
                // socket  = await socket.connect();
                this.settings.height = change;
                let oldView = store.getters['view/getCurrentView']

                oldView.heightMetric = change;
                await oldView.nextVersion();
                // await store.dispatch('view/reset')
                // store.commit("view/setCurrentView", view);
                // view.animate();
            }
        },
        async mounted() {
            let view = new this.viewType('v-' + this.title, this.$route.params.owner + '/' + this.$route.params.name, this.socket, this.$route.params.version, store.getters['settings/depth'], store.getters['settings/height']);
            view.animate();
            store.commit("view/setCurrentView", view);
        },
        beforeDestroy() {
            View.stopWorld(store.getters['view/getCurrentView'])
            store.dispatch("view/reset");
        }
    }
</script>

<style scoped>
    .wrap {
        width: 100%;
        height: 100%;
        position: relative;
    }

    canvas {
        width: 100%;
        height: 100%
    }

    canvas:focus {
        outline: 1px solid crimson;
        -webkit-tap-highlight-color: rgba(255, 255, 255, 0); /* mobile webkit */
    }

    .repoTitle {
        font-size: x-large;
    }

</style>
