<template>
    <div class="container">
        <div class="row">
            <div class="col-4 col-sm-12 col-md-4" v-if="commit">
                <dl class="row">
                    <h5>Commit</h5>
                    <dt class="col-sm-3">sha1</dt>
                    <dd class="col-sm-9">{{commit['sha1'].substr(0,8)}}</dd>

                    <dt class="col-sm-3">Time</dt>
                    <dd class="col-sm-9">
                        {{new Date(commit['commitTime']).toDateString()}}
                    </dd>

                    <dt class="col-sm-3">Author</dt>
                    <dd class="col-sm-9">
                        {{commit['authorEmail']}}
                    </dd>

                    <dt class="col-sm-3">Message</dt>
                    <dd class="col-sm-9" id="message">
                        {{commit['commitShortMessage']}}
                    </dd>
                </dl>
            </div>
            <div class="col-4 col-sm-12 col-md-4" v-if="versionData">
                <dl class="row">
                    <h5>Version</h5>

                    <dt class="col-sm-5">Modified Packages</dt>
                    <dd class="col-sm-7">
                        {{versionData.modifiedPackages}}
                    </dd>

                    <dt class="col-sm-5">Modified Classes</dt>
                    <dd class="col-sm-7">
                        {{versionData.modifiedClasses}}
                    </dd>

                    <dt class="col-sm-5">Change Types</dt>
                    <dd class="col-sm-7">
                        <span v-for="item in versionData.modifiers">{{item}}, </span>
                    </dd>
                </dl>
            </div>
            <div class="col-4 col-sm-12 col-md-4" v-if="sceneState">
                <dl class="row">
                    <h5>Scene</h5>
                    <dt class="col-sm-3">FPS</dt>
                    <dd class="col-sm-9">
                        {{Math.trunc(sceneState.fps)}}
                    </dd>

                    <dt class="col-sm-3">Meshes</dt>
                    <dd class="col-sm-9">
                        <dl class="row">
                            <dt class="col-sm-4">Total</dt>
                            <dd class="col-sm-8">{{sceneState.allMeshes}}</dd>

                            <dt class="col-sm-4">Active</dt>
                            <dd class="col-sm-8">{{sceneState.activeMeshes}}</dd>
                        </dl>
                    </dd>
                </dl>
            </div>

            <div class="col-4 col-sm-12 col-md-4" v-if="selectedClass">
                <dl class="row">
                    <h5>Class data</h5>

                    <dt class="col-sm-3">Name</dt>
                    <dd class="col-sm-9">
                        {{selectedClass['filePath']}}
                    </dd>

                    <dt class="col-sm-3">Change</dt>
                    <dd class="col-sm-9">
                        {{selectedClass['changeType']}}
                    </dd>

                    <dt class="col-sm-3">Version</dt>
                    <dd class="col-sm-9">
                        {{selectedClass['version']}}
                    </dd>

                    <dt class="col-sm-3">Metrics</dt>
                    <dd class="col-sm-9">
                        <dl class="row">
                            <template v-for="(value, key) in selectedClass['metricValueMap']">
                                <dt class="col-sm-6">{{key}}</dt>
                                <dd class="col-sm-6">{{value}}</dd>
                            </template>
                        </dl>
                    </dd>
                </dl>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        name: 'EvolutionDataBoxedInformation',
        props: {
            commit: {},
            sceneState: {},
            selectedClass: {},
            versionData: {}
        }
    }
</script>
<style scoped>
    div, p {
        word-wrap: break-word;
    }

    .container {
        font-size: small;
    }

    h5 {
        border-bottom: 1px solid white;
        width: 90%;
    }

    #message{
        max-height: 50px;
        min-height: 50px;
        word-wrap: break-word;
    }
</style>
