<template>
    <div>
        <Navbar></Navbar>
        <transition
                mode="out-in"
                name="fade"
        >
            <router-view :key="$route.fullPath "></router-view>
        </transition>
    </div>
</template>

<script>
    import Navbar from "./components/shared/Navbar";
    import store from "./store"
    export default {
        name: "App",
        components: {Navbar},
        data(){
            return{
                settings: {}
            }
        },
        methods:{
            hash(obj){
                return JSON.stringify(obj)
            }
        },
        computed:{
            getSettings(){
                return store.getters['settings/all']
            }
        },
        watch:{
            getSettings:{
                handler: function(newValue) {
                    this.settings = newValue
                },
                deep: true
            }
        }
    }
</script>

<style>
    @import url('https://fonts.googleapis.com/css?family=Varela+Round');

    body {
        font-family: 'Varela Round', Helvetica, Arial, sans-serif;
    }

    .fade-enter-active,
    .fade-leave-active {
        transition-duration: 0.3s;
        transition-property: opacity;
        transition-timing-function: ease;
    }

    .fade-enter,
    .fade-leave-active {
        opacity: 0
    }

    body {
        background-color: black !important;
        color: white !important;
        overflow: scroll;
    }


</style>
