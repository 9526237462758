import {ActionTree} from 'vuex';
import {RootState} from '../types';
import {ViewState} from "./types";
import {View} from "../../view/View";
import store from "../../store";


export const actions: ActionTree<ViewState, RootState> = {
    async reset({commit,state}): Promise<void> {
        return new Promise((resolve, reject) => {
            let view = <View>state.view;
            // View.stopWorld(view);
            store.commit("view/resetState", null);
        })
    },
};
