<template>

    <b-toast id="evo-toast" auto-hide-delay="5000"  variant="secondary" toaster="b-toaster-bottom-right" style="min-height: 500px;" >
        <template v-slot:toast-title>
            <div class="d-flex flex-grow-1 align-items-baseline">
                <template v-if="selectedClass">

                <strong class="mr-auto">{{selectedClass['type'].toUpperCase()}} Data</strong>
                </template>
            </div>
        </template>
        <div class="col-12" v-if="selectedClass" style="min-height: 230px">
            <dl class="row">
                <dt class="col-sm-3">Name</dt>
                <dd class="col-sm-9" style="word-break: break-all;">
                    <div>
                    {{trimFileName(selectedClass['filePath'])}}
                    </div>
                </dd>
                <template v-if="! (selectedClass['changeType'] === undefined)">

                <dt class="col-sm-3">Change</dt>
                <dd class="col-sm-9">
                    {{selectedClass['changeType']}}
                </dd>

                </template>
                <dt class="col-sm-3">Version</dt>
                <dd class="col-sm-9">
                    {{selectedClass['version']}}
                </dd>

                <template v-if="! (selectedClass['metricValueMap'] === undefined || selectedClass['metricValueMap'].length == 0)">
                <dt class="col-sm-3">Metrics</dt>
                <dd class="col-sm-9">
                    <dl class="row">
                        <template v-for="(value, key) in selectedClass['metricValueMap']">
                            <dt class="col-sm-6">{{key}}</dt>
                            <dd class="col-sm-6">{{value}}</dd>
                        </template>
                    </dl>
                </dd>
                </template>
            </dl>
        </div>
    </b-toast>

</template>

<script>
    import store from '../../store'
    export default {
        name: "EvolutionToast",

        data() {
            return {
                count: 0,
                selectedClass: null,
                isVisible:false
            }
        },
        computed: {
            getLoadedClass(){
                return store.getters['m3Class/currentM3Class']
            }
        },
        watch:{
            getLoadedClass(change){
                this.selectedClass = change;
                this.addToast()
            }
        },
        methods: {
            addToast() {
                this.$bvToast.show('evo-toast')
            },
            trimFileName(name){
                // if(name.length > 25) {
                //     return (name.length > 25 ? "..." : "") + name.slice(name.length - 25, name.length);
                // }else{
                    return name
                // }
            }
        },
        mounted() {
            console.log('toast mounted')
        }
    }
</script>

<style scoped>
    .fade-enter-active, .fade-leave-active {
        transition: opacity .5s;
    }
    .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
        opacity: 0;
    }
    [v-cloak] > * { display:none; }
    [v-cloak]::before { content: "loading..."; }
    .text {
        overflow: visible;
    }

    .dd{
        white-space: normal
    }

</style>

<style>
    .toast-body{
        min-height: 230px;
    }
</style>
