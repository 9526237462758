import {
    AbstractMesh, ActionManager,
    Color3,
    Color4,
    Matrix,
    Mesh,
    MeshBuilder,
    Scene,
    StandardMaterial,
    Vector3
} from "@babylonjs/core";
import {Text} from "./Text"
import {v4 as uuidv4} from 'uuid';
import {AdvancedDynamicTexture} from "@babylonjs/gui";

export interface CubeConfig {
    repositoryVersion?: number;
    entityType?: string;
    id: string,
    name?: string,
    scene?: Scene,
    width?: number,
    height?: number,
    depth?: number,
    x: number,
    y: number,
    z: number
    intensity?:number,
    alpha?:number,
    deleted?:boolean,
    placeholder?:boolean,
    nameString?:string
    authorString?:string
    tags?:string[]
}

export interface CustomMesh extends Mesh{
    config?:CubeConfig,
    entityType?:string,
    repositoryVersion?:number,
    nameString?:string
    authorString?:string
    tags?:string[],
    intensity?:number,
    alpha?:number,
    text?:Text
}

export abstract class Cuboid {
    mesh: CustomMesh;
    adt:AdvancedDynamicTexture;
    protected constructor(scene: Scene, adt:AdvancedDynamicTexture) {
        this.mesh = <CustomMesh> MeshBuilder.CreateBox(uuidv4(), {
            height: 1,
            width: 1,
            depth: 1
        }, scene);
        this.mesh.setEnabled(false);
        this.mesh.freezeNormals();
        this.mesh.freezeWorldMatrix();
        this.adt = adt;
    }

    getInstance(config: CubeConfig) {
        let instance;
        if (config.name != undefined) {
            instance = this.mesh.createInstance(config.name);
            instance.id = config.id;
        } else {
            instance = this.mesh.createInstance(config.id);
        }
        instance.setPivotMatrix(Matrix.Translation(1/2,1/2,1/2));
        instance.position.x = config.x
        instance.position.y = config.y
        instance.position.z = config.z
        if(config.width && config.height && config.depth) {
            instance.scaling.x = config.width
            instance.scaling.y = config.height
            instance.scaling.z = config.depth
        }
        instance.doNotSyncBoundingInfo = false;
        instance.freezeWorldMatrix();
        instance.setEnabled(true);

        return instance
    }

    getClone(config: CubeConfig) {
        let clone:CustomMesh;
        if (config.name != undefined) {
            clone = this.mesh.clone(config.name,null,true,false);
            clone.id = config.id;
        } else {
            clone = this.mesh.clone(config.id,null, true,false);
        }
        clone.setPivotMatrix(Matrix.Translation(1/2,1/2, 1/2));
        clone.position.x = config.x
        clone.position.y = config.y
        clone.position.z = config.z
        if(config.width && config.height && config.depth) {
            clone.scaling.x = config.width
            clone.scaling.y = config.height
            clone.scaling.z = config.depth
        }
        clone.doNotSyncBoundingInfo = false;
        clone.freezeWorldMatrix();
        clone.freezeNormals()
        clone.setEnabled(true);
        clone.cullingStrategy = AbstractMesh.CULLINGSTRATEGY_OPTIMISTIC_INCLUSION_THEN_BSPHERE_ONLY;
        clone.actionManager =  new ActionManager(clone.getScene());


        return clone
    }

    static setMaterial(mesh: Mesh, r: number, g: number, b: number, alpha:number): void {
        let mat = new StandardMaterial((r + g + b).toString(), mesh.getScene());
        mat.diffuseColor = new Color3(r, g, b);
        mat.specularColor = new Color3(0, 0, 0);
        mat.alpha = 1;
        mat.freeze();
        mesh.material = mat
    }

    static makeText(mesh: CustomMesh, adt: AdvancedDynamicTexture, text: string | undefined){
        if(text!=undefined) {
            mesh.text = new Text({adt: adt, mesh: mesh, text: text});
            return mesh.text
        }
    }

    static hide(mesh:Mesh){
        mesh.setEnabled(false)
    }

    static show(mesh:Mesh){
        mesh.setEnabled(true)
    }
}

