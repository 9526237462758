<template>

    <div id="timeline">
        <apexchart ref="realtimeChart" type="bubble" :options="chartOptions" :series="series" height="160px"></apexchart>
    </div>
</template>


<script>
    // import IEcharts from 'vue-echarts-v3/src/full.js';
    import 'echarts/lib/chart/scatter'
    import 'echarts/lib/chart/line'
    import 'echarts/lib/component/markLine'
    import {getRepositoryTimeline, getRepositoryTimelineDaily} from "../../api";
    import store from "../../store"
    // import 'echarts/lib/chart/scatter'
    // import 'echarts/lib/component/polar'


    export default {
        name: 'TimelineApex',
        props: {},
        data: () => ({
            version: 0,
            instance: null,
            yAxisLabels: [],
            series: [{
                name: 'Bubble1',
                data: []
            }],
            chartOptions: {
                chart: {
                    animations: {
                        enabled: false
                    },

                    toolbar: {
                        show: false
                    },
                    height:160,
                    type: 'bubble',
                    events: {
                        click: (e, chart, config) => {
                            //console.log('click', config)
                            // if (dataPointIndex !== -1) {
                            //     this.addAnnotation(dataPointIndex);
                            //     this.$store.dispatch('view/evolutionVersion', dataPointIndex);
                            // }
                        },
                    }
                },
                grid: {
                    padding: {
                        left: 10,
                        right: 0,
                        bottom:60,
                        top:0
                    },
                    yaxis:{
                        lines: {
                            show: false
                        },
                    }
                },
                dataLabels: {
                    enabled: false
                },
                fill: {
                    opacity: 0.8
                },
                title: {
                    text: undefined,
                },
                xaxis: {
                    categories:[],
                    type: 'numeric',
                    axisBorder: {
                        show: false,
                    }
                },
                yaxis: {

                    labels:{
                        minWidth: 100,
                        formatter: function(val, index) {
                            return store.getters['timeline/getYAxisLabels'][index]
                        }
                    },

                    type: 'category',
                },
                markers: {
                    size: 0,
                    strokeWidth: 0,
                },
                stroke: {
                    show: false,
                },
                annotations: {
                    xaxis: [{
                        x: 1,
                        strokeDashArray: 0,
                        borderColor: '#775DD0',
                        label: {
                            borderColor: '#775DD0',
                            orientation: 'horizontal',
                            style: {
                                color: '#fff',
                                background: '#775DD0',
                            },
                            text: String(1),
                        },
                    }],
                },
            },
        }),
        methods: {
            async setDataSeries() {
                getRepositoryTimeline(this.$route.params.owner + '/' + this.$route.params.name).then(data => {
                    let dataSeries = []
                    let yAxisSeries = [];
                    let xAxisSeries = []
                    for (let [key, value] of Object.entries(data)) {
                        let versionNumber = key;
                        let versionData = value;
                        xAxisSeries.push(versionNumber)
                        for (let [key1, value1] of Object.entries(versionData)) {
                            let finalValue = value1 > 0 ? value1 : 0;
                            if (yAxisSeries.indexOf(key1) >= 0) {
                                dataSeries.push([Number(versionNumber), yAxisSeries.indexOf(key1), finalValue*2])
                            } else {
                                yAxisSeries.push(key1)
                                dataSeries.push([Number(versionNumber), yAxisSeries.indexOf(key1), finalValue*2])
                            }
                        }
                    }
                    store.commit('timeline/setYAxisLabels', yAxisSeries)
                    this.series[0].data = dataSeries
                    this.$refs.realtimeChart.updateOptions(
                        this.chartOptions
                    , false, false)
                    this.$refs.realtimeChart.updateSeries([{
                        data: this.series[0].data
                    }], false, false)
                })
            },
            addAnnotation(index){
                if (this.$refs.realtimeChart) {
                    this.$refs.realtimeChart.clearAnnotations();
                    this.$refs.realtimeChart.addXaxisAnnotation({
                        x: index,
                        strokeDashArray: 0,
                        borderColor: '#775DD0',
                        label: {
                            borderColor: '#775DD0',
                            orientation: 'horizontal',
                            style: {
                                color: '#fff',
                                background: '#775DD0',
                            },
                            text: String(index),
                        },
                    });
                }
            }
        },
        computed:{
            getCurrentVersion(){
                return store.getters['view/getEvolutionVersion'];
            },
            getHighlightSeries(){
                return store.getters['timeline/getHighlightSeries']
            }
        },
        watch: {
            getCurrentVersion(change) {
                this.version = change
                this.addAnnotation(change)
            }
        },
        mounted() {
            this.setDataSeries();
            console.log('timelone mounted')
        }
    }
</script>

<style scoped>
    #timeline {
        width: 100%;
        height: 120px;
        margin-bottom: 5px;
    }
</style>
