<template>
    <div class=" col-4" style="">
        <div class="col-md-12">
            <h4>Names PopUp Settings</h4>
        </div>
        <div class="form-row align-items-center col-md-12">

            <div class="col-12">
                <label class="my-1 mr-2" for="inlineFormCustomSelectPref2">Mode</label>
                <select class="custom-select my-1 mr-sm-2" id="inlineFormCustomSelectPref2"
                        v-model.lazy="selected.names.mode">
                    <option v-for="(item,index) in modes" :value="item" :selected="index == 1">{{item}}
                    </option>
                </select>
            </div>
            <div class="col-12">

                <div class="form-check">
                    <input class="form-check-input" type="checkbox" value="" id="hoverEnabled"
                           v-model.lazy="selected.names.hoverEnabled">
                    <label class="form-check-label" for="hoverEnabled">
                        Hover labels
                    </label>
                </div>
            </div>
            <div class="col-auto">
                <div class='' style="margin-top: .5rem;">
                    <h6>Text Options</h6>
                </div>
                <b-form-group>
                    <b-form-radio-group id="radio-group-2" v-model.lazy="selected.names.text" name="radio-sub-component">
                        <b-form-radio value="filename">File name</b-form-radio>
                        <b-form-radio value="authorname">Author name</b-form-radio>
                        <b-form-radio value="filenameauthorname">File name + Author name</b-form-radio>
                    </b-form-radio-group>
                </b-form-group>
            </div>
            <div class="col-auto" v-if="selected.names.mode == 'Custom Regex'">
                <label class="my-1 mr-2" for="inlineFormCustomSelectPref3">Regex Pattern</label>
                <input type="text" id="inlineFormCustomSelectPref3" placeholder="Regex" value="" required
                       v-model.lazy="selected.names.regex">
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        name: 'NamesPopupSettings',
        props: {
            modes: {},
            selected: {}
        }
    }
</script>
<style scoped>

    input {
        border: 1px solid transparent;
        background-color: #f1f1f1;
        padding: 10px;
        font-size: 16px;
    }

    input[type=text] {
        background-color: #f1f1f1;
        width: 100%;
    }

    input[type=submit] {
        background-color: DodgerBlue;
        color: #fff;
    }

    .autocomplete-items div {
        padding: 10px;
        cursor: pointer;
        background-color: #fff;
        border-bottom: 1px solid #d4d4d4;
    }

    .autocomplete-items div:hover {
        /*when hovering an item:*/
        background-color: #e9e9e9;
    }

</style>
<style>

    form {
        color: black !important;
    }

    .form-inline label {
        justify-content: left;
    }

</style>
