import {Module} from 'vuex';
import {getters} from './getters';
import {actions} from './actions';
import {mutations} from './mutations';
import {M3ClassState} from './types'
import {RootState} from '../types';
import {Color3, Color4} from "@babylonjs/core";

export const state: M3ClassState = {
    m3Class: undefined,
    mesh:undefined,
    highlightColor: Color4.FromColor3(Color3.Green()),
    selected: false,
    error: false,
};

const namespaced: boolean = true;

export const m3Class: Module<M3ClassState, RootState> = {
    namespaced,
    state,
    getters,
    actions,
    mutations,
};
