export const ALL = "All"
export const CHANGES_ONLY = "Changes Only"
export const REGEX = "Custom Regex"
export const PACKAGE_ONLY = "Package Only"
export const CLASS_ONLY = "Class Only"
export const NONE = "None"
export const TAGS = "Tags"
export const RECORD = "Record"

export const FILENAME = "filename"
export const AUTHORNAME = "authorname"
export const FILENAMEAUTHORNAME = "filenameauthorname"
