import {MutationTree} from 'vuex';
import {M3Class, M3ClassState} from './types';
import {AbstractMesh} from "@babylonjs/core";

export const mutations: MutationTree<M3ClassState> = {
    m3ClassLoaded(state, payload: {classObj: M3Class, mesh:AbstractMesh}) {
        state.error = false;
        state.m3Class = payload.classObj;
        state.mesh = payload.mesh;
        state.selected = true
    },
    m3ClassLoadedDataOnly(state, classObj: M3Class) {
        state.error = false;
        state.m3Class =classObj;
        state.selected = true
    },
    m3ClassError(state) {
        state.error = true;
        state.m3Class = undefined;
    },
};
