import {View} from "./View";
import {Socket} from "../Socket";
import {M3ClassHistory} from "../glyphs/M3ClassHistory";
import {M3Class} from "../glyphs/M3Class";
import {Topics} from "../Topic";
import {getClassVersionData} from "../api";
import {M3Package} from "../glyphs/M3Package";

export class EvolutionMatrixView extends View {

    protected BASE_HEIGHT = 2;
    protected BASE_OFFSET = 1;

    protected classHistoryRoot: M3ClassHistory;
    protected classRoot: M3Class;
    protected packageRoot: M3Package;

    constructor(canvasElement: string, repoId: number, socket: Socket) {
        super(canvasElement, repoId, socket);
        this.classHistoryRoot = new M3ClassHistory(this._scene, this.adt);
        this.classRoot = new M3Class(this._scene, this.adt);
        this.packageRoot = new M3Package(this._scene, this.adt);
        this.makeSocketRequest(repoId);
    }

    public callback(x: any[], evolutionMatrixView: EvolutionMatrixView) {
        for (let i = 0; i < x.length; i++) {
            let value = x[i];

                evolutionMatrixView.classRoot.drawClone(value, getClassVersionData)

        }
    }

    protected async makeSocketRequest(repoId: number) {
        let self = this;
        await this.init(Topics.EVOLUTION_MATRIX, this.callback, this, function () {
            // self.requestViewData(Topics.EVOLUTION_MATRIX, {repoId: repoId} )
        });
    }

    protected computeYOffset(x: any) {
        return this.BASE_HEIGHT + x.height / 2;
    }

    protected getBaseDepth(x: any) {
        return x.width + 2 * this.BASE_OFFSET
    }

    protected getBaseWidth(x: any) {
        return x.x + x.width + 2 * this.BASE_OFFSET
    }

    protected getBaseXPosition(x: any, xF: any) {
        return x.x + this.getBaseWidth(xF) / 2 - 4 * this.BASE_OFFSET
    }

}
