<template>
    <b-table :fields="fields" :items="items">
        <template v-slot:cell(name)="data">
            <a :href="`/#/evolution/${data.item.id}/all`" :class="{ 'disabled' : data.item.running == true}">{{ data.value }}</a>
        </template>
    </b-table>
</template>

<script>
    import {getHomeRepositoryListing} from "../../api";

    export default {
        name: "RepositoryListing",
        data() {
            return {
                fields: [
                    {
                        key: 'name',
                        label: 'Project Name'
                    },
                    {
                        key: 'owner',
                        label: 'Project Owner'
                    }
                    ,
                    {
                        key: 'running',
                        label: 'Analysis status',
                        formatter: (x)=> x === false ? "Completed" : "Running"
                    }
                ],
                items: []
            }
        },
        methods: {
            async getRepositoriesListing() {
                return await getHomeRepositoryListing()
            }
        },
        methods: {
            async getRepositoriesListing() {
                return await getHomeRepositoryListing()
            }
        },
        async mounted() {
            this.items = await this.getRepositoriesListing();
            this.$nextTick(function () {
                window.setInterval(async () => {
                    this.items = await this.getRepositoriesListing();
                },500);
            })
        }
    }
</script>

<style scoped>
    table {
        color: white;
    }

    .disabled{
        color: white;
        pointer-events: none;
    }
</style>
