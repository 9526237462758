import {GetterTree} from 'vuex';
import {RepositoryState} from './types';
import {RootState} from '../types';

export const getters: GetterTree<RepositoryState, RootState> = {
    repositoryUrl(state): string {
        const {repository} = state;
        const url = (repository && repository.url) || '';
        // console.log('url',url)
        return `${url}`;
    },

    repositoryData(state): [any] {
        const {repository}: RepositoryState = state;
        return [repository];
    },

    repositoryLoaded(state): number {
        const {repository}: RepositoryState = state;
        return (repository && repository.commits) || 0;;
    },
};
