import {Client} from '@stomp/stompjs';
import {TopicHandler, Topics} from "./Topic";
import {variables} from "./variables"


export enum SocketReqType {
    PUBLISH, SUBSCRIBE
}

export interface SocketData {
 repoId: any, version: number, metricWidthType: string, metricHeightType:string, bump:number, minWidth:number, minHeight:number, mode:string,
}

export class Socket {
    readonly topicHandler: TopicHandler = new TopicHandler();
    private client: Client;
    private topic:Topics[] = []

    constructor() {
        this.client = new Client({
            brokerURL: variables.SOCKET_URL,
            reconnectDelay: 200,
            heartbeatIncoming: 200,
            heartbeatOutgoing: 200
        });

        this.client.activate();
    }

    public connect() {
        let self = this;
        return new Promise(function (resolve, reject) {

            self.client.onConnect = () => {
                resolve(self)
            };
            self.client.onWebSocketError = error => {
                reject()
            };

        });
    }

    public async subscribe(topic: Topics) {
        this.topic.push(topic);
        // console.log('topic', topic)
        await this.client.subscribe(this.topicHandler.getEndpoint(SocketReqType.SUBSCRIBE, topic), async message => {
            const payload = JSON.parse(message.body);
            let callback = this.topicHandler.getCallback(topic);
            if (callback) {
                await callback.callback(payload, callback.obj)
            }
        })
    }

    public publish(topic: Topics, data: SocketData) {
        let body = {}
        if(data){
            body = data;
        }
        this.client.publish({
            destination: this.topicHandler.getEndpoint(SocketReqType.PUBLISH, topic),
            body: JSON.stringify(body)
        });
    }

    public async reset(){
        this.client.forceDisconnect()
        await this.connect();
    }

}
