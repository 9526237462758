import {MutationTree} from 'vuex';
import {M3Commit, M3CommitState} from './types';

export const mutations: MutationTree<M3CommitState> = {
    m3CommitLoaded(state, payload: M3Commit) {
        state.error = false;
        state.commit = payload;
    },
    m3CommitError(state) {
        state.error = true;
        state.commit = undefined;
    },
};
