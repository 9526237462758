import {GetterTree} from 'vuex';
import {M3CommitState} from './types';
import {RootState} from '../types';

export const getters: GetterTree<M3CommitState, RootState> = {
    currentM3Commit(state): Object {
        const {commit} = state;
        // console.log('repo',repository)
        const frameM3Commit = (commit) || '';
        return [frameM3Commit];
    },
};
