<template>
    <div class="overlay" v-if="dataR && isVisible" :class="!isVisible ? 'ard1' : '' ">
        <div class="title">
             <div class="l">{{version}}/{{versions}} versions</div>
        <div class="">{{dataR['classHistories']}} class histories</div>
        <div class="">{{dataR['packageHistories']}} package histories</div>
        </div>
    </div>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";
    import store from '../../../store'

    export default {
        name: "CanvasEvolution",
        data() {
            return {
                repoName: null,
                repoOwner: null,
                dataR: null,
                version: store.getters['view/markLineIndex'],
                versions: store.getters['view/getEvolutionVersions'],
                isVisible: store.getters['settings/canvasEvolution'],

            }
        },
        computed:{
            getVersion(){
                return store.getters['view/markLineIndex']
            },
            getVersions(){
                return store.getters['view/getEvolutionVersions']
            },
            getEvolutionSettings(){
                return store.getters['settings/canvasEvolution']
            }
        },
        watch:{
            getVersion(change){
                this.version = change
            },
            getVersions(change){
                this.versions = change
            },
            getEvolutionSettings(change){
                this.isVisible = change;
            }
        },
        methods: {
            ...mapActions('m3Repository', ['fetchData']),
            ...mapGetters('m3Repository', ['repositoryUrl', 'repositoryData']),
            async initRepositoryMetaStore() {
                return await this.fetchData(this.$route.params.owner + '/' + this.$route.params.name);
            },
            extractNames(url) {
                let splitName = url.split('/');
                this.repoName = splitName[splitName.length - 1];
                this.repoOwner = splitName[splitName.length - 2]
            },
        },
        async mounted() {
            await this.initRepositoryMetaStore();
            this.extractNames(this.repositoryUrl());
            this.dataR = this.repositoryData()[0]
        }
    }
</script>

<style scoped>

    .overlay {
        margin-top: 5px;
        position: absolute;
        top: 0;
        right: 0;
        color: white;
        padding: 5px;
        pointer-events: none;

    }

    .overlay > div.title {
        padding: 10px;
        background-color: rgba(0, 0, 0, 0.6);
        border-radius: 5px;
    }

    span.connection {
        font-size: 2rem;
    }


    .l{
        font-size:1.55rem;

    }

</style>
