import {MutationTree} from 'vuex';
import {SettingsState} from './types';
import {CustomMesh} from "../../glyphs/Cuboid";
import {Color3} from "@babylonjs/core";
import store from '../.'
import {M3Class} from "../../glyphs/M3Class";
import {M3Package} from "../../glyphs/M3Package";
export const mutations: MutationTree<SettingsState> = {
    setSettings(state, payload: SettingsState): any {
        Object.assign(state, payload)
    },
    setSettingsView(state, payload:any):any{
        state.view = payload
    },
    setSettingsElision(state, payload:any):any{
        state.elision = payload
    },
    setSettingsNames(state, payload:any):any{
        state.names = payload
    },
    setSettingsColorization(state, payload: {
        mode: string
        regex: { regex: string, color: Color3 }
        isRecording: boolean, tags: { tag: string, color: Color3 }[]
    }):any {
        let oldMode = state.colorization.mode;
        if (oldMode != payload.mode) {
            store.getters['view/getCurrentView']._scene.meshes.filter((mesh: CustomMesh) => mesh.id.startsWith("M3-")).forEach((mesh: CustomMesh) => {
                if (mesh.entityType == 'class') {
                    M3Class.setBaseMaterial(mesh)
                } else if (mesh.entityType == 'package') {
                    M3Package.setBaseMaterial(mesh)
                }
            })
            state.colorization.isRecording = payload.isRecording
            state.colorization.regex = payload.regex
            state.colorization.mode = payload.mode
            state.colorization.tags = payload.tags

        }
    },

    colorizationTag(state, payload: { tag: string, color: Color3 }): any {
        // console.log('p;m',payload, state.colorization.tags)
        state.colorization.tags.push(payload)
        let scene = store.getters['view/getCurrentView']._scene
        // @ts-ignore
        scene.meshes.filter((mesh:CustomMesh)=>mesh.repositoryVersion < store.getters['view/getEvolutionVersion']).forEach((mesh:CustomMesh)=>{

            // @ts-ignore
            if(mesh.tags.indexOf(payload.tag)>=0 ){
                M3Class.staticSetMaterial(mesh, payload.color)

            }
        })

    },
    colorizationRemoveTag(state, payload: {tag:string, index:number}): any {
        let scene = store.getters['view/getCurrentView']._scene

        // @ts-ignore
        scene.meshes.filter((mesh:CustomMesh)=>mesh.repositoryVersion < store.getters['view/getEvolutionVersion']).forEach((mesh:CustomMesh)=>{

            // @ts-ignore
            if(mesh.tags.indexOf(payload.tag)>=0){
                M3Class.setBaseMaterial(mesh)
            }
        })
        // state.colorization.tags = state.colorization.tags.filter(item => item.tag !== payload.tag)
        // @ts-ignore
        state.colorization.tags.splice(payload.index, 1, null)


    },
    colorizationRemoveRecord(state, payload: {mesh:CustomMesh, index:number}): any {
        M3Class.setBaseMaterial(payload.mesh)
        // @ts-ignore
        state.colorization.recordedMeshes.splice(payload.index, 1, null)

        // state.colorization.recordedMeshes = state.colorization.recordedMeshes.filter(item => item!=null &&  item.mesh.id !== payload.id)
    },
    isRecording(state, payload: boolean): any {
        state.colorization.isRecording = payload
    },
    recordedMesh(state, payload: {mesh:CustomMesh, color:Color3}): any {
        state.colorization.recordedMeshes.push(payload)
        // if(payload.mesh.entityType == 'class')
        M3Class.staticSetMaterial(payload.mesh, payload.color)
    },

    canvasCommitMessage(state,payload:boolean){
        state.view.canvasCommitMessage = payload
    },
    canvasCommitTerminal(state,payload:boolean){
        state.view.canvasCommitTerminal = payload
    }
};
