import {GetterTree} from 'vuex';
import {ViewState} from './types';
import {RootState} from '../types';
import {View} from "../../view/View";
import {LinesMesh, Vector3} from "@babylonjs/core";
import {Socket} from "../../Socket";
import {CustomMesh} from "../../glyphs/Cuboid";

export const getters: GetterTree<ViewState, RootState> = {
    getCurrentView(state): View {
        let {view} = state;
        return <View> view;
    },
    getIsPlaying(state): boolean {
        let {isPlaying} = state;
        return isPlaying;
    },
    getTranscript(state): [] {
        let {transcript} = state;
        return transcript;
    },
    getRepoId(state): string {
        let {repoId} = state;
        return repoId;
    },
    getEvolutionSpeed(state): number {
        let {speed} = state;
        return speed;
    },
    getEvolutionVersion(state): number {
        let {version} = state;
        return version;
    },
    getEvolutionVersionNext(state): number {
        let {nextVersion} = state;
        return nextVersion;
    },
    getEvolutionVersions(state): any {
        let {versions} = state;
        return versions;
    },
    getEvolutionCommit(state): any {
        let {commit} = state;
        return commit;
    },
    getEvolutionVersionData(state): any {
        let {versionData} = state;
        return versionData;
    },
    getEvolutionRenameLines(state):LinesMesh[] {
        return state.renameLines.meshes
    },

    getSocket(state):Socket|null {
        if(state.socket) {
            return state.socket
        }else{
            return null;
        }
    },

    hoverMesh(state):Text|null {
        return state.hoverMesh
    },

    playingMode(state):string {
        let {playingMode} = state;
        return playingMode;
    },

    displayVersion(state):number {
        let {displayVersion} = state;
        return displayVersion;
    },
    versionsList(state):number[] {
        let {versionsList} = state;
        return versionsList;
    },
    markLineIndex(state):number {
        let {markLineIndex} = state;
        return markLineIndex;
    },

    isLoading(state):boolean{
        let {isLoading} = state;
        return isLoading;
    }
};
