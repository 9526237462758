import {ActionTree} from 'vuex';
import {RootState} from '../types';
import {SettingsState} from "./types";
import {View} from "../../view/View";
import store from "../../store";
import {getRepositoryClassVersionTimelineHighlights, getRepositoryPackageVersionTimelineHighlights} from "../../api";


export const actions: ActionTree<SettingsState, RootState> = {

};
