import {Socket} from "../Socket";
import {Topics} from "../Topic";
import {getClassVersionData, getPackageVersionData} from "../api";
import {EvolutionMatrixView} from "./EvolutionMatrixView";
import {ArcRotateCamera, LinesMesh, Mesh, Vector3} from "@babylonjs/core";
import store from '../store';
import {DECL_STMT, NOM} from "./options/SupportedMetrics";
import {moveCameraToPoint} from "../glyphs/utils/animation";
import {CustomMesh} from "../glyphs/Cuboid";

export class EvolutionMatrixVersionedView extends EvolutionMatrixView {

    public depthMetric: string | undefined;
    public heightMetric: string | undefined;

    constructor(canvasElement: string, repoId: number, socket: Socket, version?: number, depthMetric?: string, heightMetric?: string) {
        super(canvasElement, repoId, socket);
        store.commit('view/isLoading', true)
        this.depthMetric = depthMetric;
        this.heightMetric = heightMetric;

    }


    static async timedUpdate(evolutionMatrixVersionedView: EvolutionMatrixVersionedView, seconds?: number) {
        console.log("v-", store.getters['view/getEvolutionVersion'], " update", new Date());
        let loopElapsed = 0;
        const __ret = await this.waitForAnimations(evolutionMatrixVersionedView, loopElapsed);
        let animationStarted = __ret.animationStarted;
        loopElapsed = __ret.loopElapsed;
        if (seconds) {
            await evolutionMatrixVersionedView.sleep((seconds * 1000) - loopElapsed + 25)
        }
        if (store.getters['view/getIsPlaying'] && !animationStarted) {
            store.commit('view/evolutionVersion', store.getters['view/getEvolutionVersionNext']);

            evolutionMatrixVersionedView.requestViewData(Topics.EVOLUTION_MATRIX_VERSIONED_UPDATE, {
                repoId: store.getters['view/getRepoId'],
                version: store.getters['view/getEvolutionVersion'],
                metricWidthType: store.getters['settings/depth'],
                metricHeightType: store.getters['settings/height'],
                bump: store.getters['settings/bumpSize'],
                minWidth: store.getters['settings/minWidth'],
                minHeight: store.getters['settings/minHeight'],
                mode:store.getters['view/playingMode']

            })

        }
    }

    private static async waitForAnimations(evolutionMatrixVersionedView: EvolutionMatrixVersionedView, loopElapsed: number) {
        let animationStarted = EvolutionMatrixVersionedView.checkAnimatable(evolutionMatrixVersionedView);
        while (animationStarted) {
            animationStarted = EvolutionMatrixVersionedView.checkAnimatable(evolutionMatrixVersionedView);
            await evolutionMatrixVersionedView.sleep(50);
            loopElapsed += 50;
        }
        return {animationStarted, loopElapsed};
    }

    private static checkAnimatable(evolutionMatrixVersionedView: EvolutionMatrixVersionedView) {
        return evolutionMatrixVersionedView._scene.animatables.length !== 0;
    }

    public async startCallback(data: any, evolutionMatrixVersionedView: EvolutionMatrixVersionedView) {
        store.commit('view/versionsList', data['versionList']);

        store.commit('view/evolutionVersions', data['versions']);
        store.commit('view/evolutionVersionNext', data['nextVersion'])
        store.commit('view/evolutionCommit', data['commit']);
        store.commit('view/evolutionVersionData', data['versionData']);
        store.commit('view/markLineIndex', data['markLineIndex'])

        while (evolutionMatrixVersionedView._camera === undefined) {
            await evolutionMatrixVersionedView.sleep(40)
        }
        moveCameraToPoint(<ArcRotateCamera>evolutionMatrixVersionedView._camera, new Vector3(data["cameraPosition"]['x'], 0, data["cameraPosition"]['y']), Math.max(data["cameraPosition"]['x'], data["cameraPosition"]['y']) * 3.5)
        evolutionMatrixVersionedView.moveGroundToPoint(data["cameraPosition"]['x'], data["cameraPosition"]['y'])
        let pointLightsCorner = data['lights'];
        // console.log('lights,', pointLightsCorner)
        for (let i = 0; i < pointLightsCorner.length; i++) {
            let listPoint = pointLightsCorner[i];
            evolutionMatrixVersionedView.addLight(evolutionMatrixVersionedView._scene, new Vector3(listPoint[0], 100, listPoint[1]))
        }
        let x: [any] = data['positions'];
        evolutionMatrixVersionedView._scene.blockfreeActiveMeshesAndRenderingGroups = true;
        for (let i = 0; i < x.length; i++) {
            let value = x[i];
            let mesh: Mesh;
            let clickCallback: Function | null = null;
            if (value.entityType === "class") {
                clickCallback = getClassVersionData;
                mesh = await evolutionMatrixVersionedView.classRoot.drawClone(value, clickCallback, evolutionMatrixVersionedView);
            } else if (value.entityType === "package") {
                clickCallback = getPackageVersionData;
                mesh = await evolutionMatrixVersionedView.packageRoot.drawClone(value, clickCallback, evolutionMatrixVersionedView);
            }
        }
        evolutionMatrixVersionedView._scene.blockfreeActiveMeshesAndRenderingGroups = false;
        store.commit('view/isLoading', false)


    }

    protected async checkToastUpdate(value: any) {
        // console.log(value.id, store.getters['m3Class/currentM3Class'].id)
        // console.log(value.name, store.getters['m3Class/currentM3Class'].name)
        if (value.id === store.getters['m3Class/currentM3Class'].id) {
            let data = await getClassVersionData(value.name);
            // console.log('data,',data)
            store.commit('m3Class/m3ClassLoadedDataOnly', data)
        }
    }

    public async updateCallback(data: any, evolutionMatrixVersionedView: EvolutionMatrixVersionedView) {
        store.commit('view/markLineIndex', data['markLineIndex'])

        store.commit('view/evolutionCommit', data['commit']);
        store.commit('view/evolutionVersionData', data['versionData']);
        store.commit('view/evolutionVersionNext', data['nextVersion'])

        // store.commit('view/evolutionVersion', data['version']);
        evolutionMatrixVersionedView.resetHighlightedMeshes();
        evolutionMatrixVersionedView.resetOvTexts();
        evolutionMatrixVersionedView.resetHiddenMeshes();
        evolutionMatrixVersionedView.resetLines();
        evolutionMatrixVersionedView.updateIfNecessary(data, evolutionMatrixVersionedView);
        let x: [any] = data['positions'];
        evolutionMatrixVersionedView._scene.blockfreeActiveMeshesAndRenderingGroups = true;
        for (let i = 0; i < x.length; i++) {
            let value = x[i];
            evolutionMatrixVersionedView.checkToastUpdate(value)
            let mesh = <CustomMesh>evolutionMatrixVersionedView._scene.getMeshByID(value.id);
            if (mesh != null) {

                if (value.entityType === "class") {
                    mesh = await evolutionMatrixVersionedView.classRoot.update(mesh, value, evolutionMatrixVersionedView);
                    evolutionMatrixVersionedView.highlightMesh(mesh);

                } else if (value.entityType === "package") {
                    mesh = await evolutionMatrixVersionedView.packageRoot.update(mesh, value, evolutionMatrixVersionedView);
                    evolutionMatrixVersionedView.highlightMesh(mesh)
                    // let text = Cuboid.makeText(mesh,evolutionMatrixVersionedView.adt,value.nameString);
                    // evolutionMatrixVersionedView.ovTexts.push(text)
                }
            } else {
                let clickCallback: Function | null = null;
                if (value.entityType === "class") {
                    clickCallback = getClassVersionData;
                    mesh = await evolutionMatrixVersionedView.classRoot.drawClone(value, clickCallback, evolutionMatrixVersionedView)
                    evolutionMatrixVersionedView.highlightMesh(mesh);
                } else if (value.entityType === "package") {
                    clickCallback = getPackageVersionData;
                    mesh = await evolutionMatrixVersionedView.packageRoot.drawClone(value, clickCallback, evolutionMatrixVersionedView)
                    evolutionMatrixVersionedView.highlightMesh(mesh);
                }
            }
            evolutionMatrixVersionedView.highlightedMeshes.push(mesh);
        }
        evolutionMatrixVersionedView._scene.blockfreeActiveMeshesAndRenderingGroups = false;

        if (store.getters['view/getIsPlaying']) {
            await EvolutionMatrixVersionedView.timedUpdate(evolutionMatrixVersionedView, store.getters['view/getEvolutionSpeed']);
        }

    }

    private updateIfNecessary(data: any, evolutionMatrixVersionedView: EvolutionMatrixVersionedView) {
        if (data['cameraPosition']) {
            moveCameraToPoint(<ArcRotateCamera>evolutionMatrixVersionedView._camera, new Vector3(data["cameraPosition"]['x'], 0, data["cameraPosition"]['y']), Math.max(data["cameraPosition"]['x'], data["cameraPosition"]['y']) * 3.5)
            evolutionMatrixVersionedView.moveGroundToPoint(data["cameraPosition"]['x'], data["cameraPosition"]['y'])
        }

        if (data['versions']) {
            store.commit('view/evolutionVersions', data['versions'])
        }
    }

    public async play() {
        // console.log('hrere', this._socket)
        store.commit('view/isPlaying', true)
        this.requestViewData(Topics.EVOLUTION_MATRIX_VERSIONED_UPDATE, {
            repoId: store.getters['view/getRepoId'],
            version: store.getters['view/getEvolutionVersionNext'],
            metricWidthType: store.getters['settings/depth'],
            metricHeightType: store.getters['settings/height'],
            bump: store.getters['settings/bumpSize'],
            minWidth: store.getters['settings/minWidth'],
            minHeight: store.getters['settings/minHeight'],
            mode:store.getters['view/playingMode']
        })
    }

    public async nextVersion() {
        let nextVersion = store.getters['view/getEvolutionVersionNext'];
        let totalVersions = store.getters['view/getEvolutionVersions'];

        // if (nextVersion <= totalVersions) {
            store.commit("view/evolutionVersion", nextVersion)
            if (!store.getters['view/getIsPlaying']) {
                await this.play()
                await this.pause()
            }
        // }
    }


    public async previousVersion() {
        let prevVersion = store.getters['view/getEvolutionVersion'] - 1;
        if (prevVersion >= 0) {
            store.commit("view/evolutionVersion", prevVersion)
            if (!store.getters['view/getIsPlaying']) {
                await this.play()
                await this.pause()
            }
        }
    }

    public async stop() {
        store.commit('view/isPlaying', false)
        store.commit("view/evolutionVersion", 0)
    }

    public async pause() {
        store.commit('view/isPlaying', false)
    }

    protected async makeSocketRequest(repoId: number) {
        let self = this;
        await this.init(Topics.EVOLUTION_MATRIX_VERSIONED_START, this.startCallback, this, function () {
            if (self.depthMetric && self.heightMetric) {
                // console.log('w/h', self.depthMetric, self.heightMetric)
                self.requestViewData(Topics.EVOLUTION_MATRIX_VERSIONED_START, {
                    repoId: repoId,
                    version: store.getters['view/getEvolutionVersion'],
                    metricWidthType: self.depthMetric,
                    metricHeightType: self.heightMetric,
                    bump: store.getters['settings/bumpSize'],
                    minWidth: store.getters['settings/minWidth'],
                    minHeight: store.getters['settings/minHeight'],
                    mode:store.getters['view/playingMode']

                })
            } else {
                self.requestViewData(Topics.EVOLUTION_MATRIX_VERSIONED_START, {
                    repoId: repoId,
                    version: store.getters['view/getEvolutionVersion'],
                    metricWidthType: DECL_STMT.be,
                    metricHeightType: NOM.be,
                    bump: store.getters['settings/bumpSize'],
                    minWidth: store.getters['settings/minWidth'],
                    minHeight: store.getters['settings/minHeight'],
                    mode:store.getters['view/playingMode']

                })
            }
        });
        await this.init(Topics.EVOLUTION_MATRIX_VERSIONED_UPDATE, this.updateCallback, this, function () {
        });
    }

    protected sleep(ms: number) {
        if (ms <= 0) {
            return new Promise(resolve => resolve())
        }
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    protected resetLines() {
        let lines = <LinesMesh[]>store.getters['view/getEvolutionRenameLines']
        for (let i = 0; i < lines.length; i++) {
            lines[i].dispose()
        }
        store.commit("view/evolutionRenameLines", [])
    }


}
