<template>
    <BaseLayout v-if="socket">
        <b-col cols="6">
            <ViewCanvas
                    :socket="socket"
                    :title="'CommitHistoryView'"
                    :viewType="viewType"
            ></ViewCanvas>
        </b-col>
        <b-col cols="6">
            <RepositoryTitle/>
            <M3CommitData/>
        </b-col>
    </BaseLayout>
</template>

<script>
    import ViewCanvas from "./M3Canvas";
    import {mapGetters} from "vuex";
    import {Socket} from "../../Socket";
    import BaseLayout from "../BaseLayout";
    import RepositoryTitle from "../evolution/RepositoryTitle";
    import M3CommitData from "../shared/M3CommitData";

    export default {
        name: "CommitHistoryView",
        components: {M3CommitData, RepositoryTitle, BaseLayout, ViewCanvas},
        data: function () {
            return {
                socket: null,
                viewType: CommitHistoryView
            }
        },
        computed: {
            ...mapGetters('repository', ['repositoryUrl'])
        },
        methods: {
            async initSocket() {
                let socket = new Socket();
                socket = await socket.connect();
                return socket;
            }

        },
        async mounted() {
            this.$Progress.start();
            this.socket = await this.initSocket();
            this.$Progress.finish()
        }
    }
</script>

<style scoped>

    #tmp {
        padding: 25px;
    }

</style>
