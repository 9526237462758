import {M3Class} from "./M3Class";
import {Color3, Scene, StandardMaterial} from "@babylonjs/core";
import {AdvancedDynamicTexture} from "@babylonjs/gui";

export class M3ClassHistory extends M3Class {

    constructor(scene: Scene, adt:AdvancedDynamicTexture) {
        super(scene, adt);
    }

    // setDefaultMaterial(): void {
    //     let blueMat = new StandardMaterial("blue", this.mesh.getScene());
    //     blueMat.diffuseColor = new Color3(0, 0, 1);
    //     blueMat.specularColor = new Color3(0, 0, 1);
    //     this.mesh.material = blueMat
    // }

}
