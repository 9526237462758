<template>
    <div>
        <CommitHashSearch/>

        <table id="dtBasicExample" class="table table-fit table-striped table-bordered table-sm" cellspacing="0" width="100%">
            <thead>
            <tr>
                <th class="th-sm-2">Sha1

                </th>
                <th class="th-sm-2">Time

                </th>
                <th class="th-sm-2">Author

                </th>
                <th class="th-sm-4">Message

                </th>

                <th class="th-sm-2">Navigate

                </th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="commit in commits" :key="commit.id">
                <td>{{commit.sha1.substring(0,9)}}</td>
                <td>{{new Date(commit.commitTime).toUTCString()}}</td>
                <td>{{commit.authorEmail}}</td>
                <td >{{commit.commitShortMessage.substring(0, Math.min(commit.commitShortMessage.length, 25))}}</td>
                <td> <button class="btn btn-info">View Commit</button></td>
            </tr>
            </tbody>
        </table>
        <nav aria-label="Page navigation example" style="float: right">
            <ul class="pagination">
                <li class="page-item" v-for="page in pages" :key="page"><a class="page-link"
                                                                           style="background-color: black"
                                                                           v-on:click="getCommits(page)">{{page}}</a>
                </li>
            </ul>
        </nav>
    </div>


</template>

<script>
    import {getRepositoryCommits} from "../../api";
    import store from "../../store";
    import CommitHashSearch from "./CommitHashSearch";

    export default {
        name: "CommitListing",
        components: {CommitHashSearch},
        data() {
            return {
                page: 0,
                pages: 0,
                commits: []
            }
        },

        methods: {
            getCommits(page) {
                getRepositoryCommits(store.getters['view/getRepoId'], page).then(res => {
                    this.commits = res.commits;
                    this.page = res.page, this.pages = res.pages
                })
            }
        },

        mounted() {
            this.getCommits(0);
        }
    }
</script>

<style scoped>

    table{
        color: white;
    }
    table.table-fit {
        width: auto !important;
        table-layout: auto !important;
    }
    table.table-fit thead th, table.table-fit tfoot th {
        width: auto !important;

    }
    table.table-fit tbody td, table.table-fit tfoot td {
        width: auto !important;
        max-height: 40px ;
        overflow: hidden;
    }
</style>
