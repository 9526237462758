<template>
    <div class="vld-parent">
        <loading :active.sync="isLoading"
                 :can-cancel="false"
                 :is-full-page="fullPage"></loading>
    </div>

</template>

<script>
    // Import component
    import Loading from 'vue-loading-overlay';
    // Import stylesheet
    import 'vue-loading-overlay/dist/vue-loading.css';
    import store from "../../store"

    export default {
        name: "Loader",
        data() {
            return {
                isLoading: true,
                fullPage: true
            }
        },
        components: {
            Loading
        },
        computed:{
            getIsLoading(){
                return store.getters['view/isLoading']
            }
        },
        watch: {
            getIsLoading(change){
                this.isLoading = change
            }
        }
    }
</script>

<style scoped>

</style>
