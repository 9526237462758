import {Module} from 'vuex';
import {getters} from './getters';
import {actions} from './actions';
import {mutations} from './mutations';
import {SettingsState} from './types'
import {RootState} from '../types';
import {DECL_STMT, NOM} from "../../view/options/SupportedMetrics";
import {CHANGES_ONLY, NONE, TAGS} from "../../view/options/SettingsModes";
import store from "../index";
import {Color3} from "@babylonjs/core";

export const settingsState: SettingsState = {
    view: {
        depth: DECL_STMT.be,
        height: NOM.be,
        canvasTitle: true,
        canvasCommitMessage: true,
        canvasCommitTerminal: true,
        canvasEvolution: true,
        bumpSize: 2,
        minWidth: 2,
        minHeight: 2
    },
    names: {
        mode: NONE,
        regex: '^[^.]+.java$',
        text: 'filename',
        hoverEnabled: true,
    },
    elision: {
        mode: NONE,
        regex: '^[^.]+.java$'
    },
    colorization:{
        mode: NONE,
        regex:{regex:'^[^.]+.java$', color: Color3.Teal()},
        tags:[],
        isRecording: false,
        recordedMeshes: []
    }
};

const namespaced: boolean = true;

export const settings: Module<SettingsState, RootState> = {
    namespaced,
    state: settingsState,
    getters,
    actions,
    mutations,
};
