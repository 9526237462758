<template>
    <form class="form-inline text-white bg-dark">
        <div class="input-group">
            <div class="input-group-prepend">
                <span class="input-group-text" id="basic-addon1">#</span>
            </div>
            <input v-model="commitHash" type="text" class="form-control" placeholder="Navigate to commit" aria-label="Username" aria-describedby="basic-addon1">
        </div>
    </form>
</template>

<script>
    import store from "../../store";
    import {getVersionFromCommitId} from "../../api";

    export default {
        name: "CommitHashSearch",
        data(){
            return{
                commitHash:''
            }
        },
        computed:{
            getCommitHash(){
                return this.commitHash
            }
        },
        watch:{
            getCommitHash(change){
                getVersionFromCommitId(store.getters['view/getRepoId'],change).then(x=>{
                })
            }
        }
    }
</script>

<style scoped>

</style>
