<template>
    <div class="col-4">
        <div class="col-md-12">
            <h4>General Settings</h4>
        </div>
        <div class="col-md-12">
            <div class="col-auto">

                <div class="form-check">
                    <input class="form-check-input" type="checkbox" value="" id="defaultCheck1"
                           v-model.lazy="selected.view.canvasTitle">
                    <label class="form-check-label" for="defaultCheck1">
                        Title
                    </label>
                </div>
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" value="" id="defaultCheck2"
                           v-model.lazy="selected.view.canvasCommitTerminal">
                    <label class="form-check-label" for="defaultCheck2">
                        Date/author
                    </label>
                </div>
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" value="" id="defaultCheck3"
                           v-model.lazy="selected.view.canvasCommitMessage">
                    <label class="form-check-label" for="defaultCheck3">
                        Commit message
                    </label>
                </div>
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" value="" id="defaultCheck4"
                           v-model.lazy="selected.view.canvasEvolution">
                    <label class="form-check-label" for="defaultCheck4">
                        Versions
                    </label>
                </div>
            </div>
            <div class="col-12">
                <b-button variant="outline-primary" style="width: 100%" v-on:click="fullScreen">
                    <font-awesome-icon :icon="['fas', 'compress']"/>
                    Full Screen
                </b-button>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        name: 'GeneralSettings',
        props: {
            fullScreen: {},
            selected: {},
            supportedMetrics: {}
        }
    }
</script>
<style scoped>

    input {
        border: 1px solid transparent;
        background-color: #f1f1f1;
        padding: 10px;
        font-size: 16px;
    }

    input[type=text] {
        background-color: #f1f1f1;
        width: 100%;
    }

    input[type=submit] {
        background-color: DodgerBlue;
        color: #fff;
    }

    .autocomplete-items div {
        padding: 10px;
        cursor: pointer;
        background-color: #fff;
        border-bottom: 1px solid #d4d4d4;
    }

    .autocomplete-items div:hover {
        /*when hovering an item:*/
        background-color: #e9e9e9;
    }

</style>
<style>

    form {
        color: black !important;
    }

    .form-inline label {
        justify-content: left;
    }

</style>
