<template>
    <b-navbar toggleable="sm" type="dark" variant="dark" fixed="top">
        <b-navbar-toggle target="nav-text-collapse"></b-navbar-toggle>

        <b-navbar-brand>M3tricity</b-navbar-brand>

        <b-collapse id="nav-text-collapse" is-nav>
            <b-navbar-nav :key="index" v-for="(item, index) in menuItems">
                <b-nav-item :to="{ name: item.link }">
                    <!--                    <b-icon :icon="item.icon"></b-icon>-->
                    {{item.title}}
                </b-nav-item>
            </b-navbar-nav>
        </b-collapse>
        <b-navbar-nav class="ml-auto" v-if="$store.getters['view/getCurrentView']!=null">
            <b-nav-item-dropdown right>
                <!-- Using 'button-content' slot -->
                <template v-slot:button-content>
                    <em>{{$store.getters['view/playingMode']}}</em>
                </template>
                <b-dropdown-item v-for="mode in modes" :key="mode.mode" v-on:click="changeMode"
                                 :to="{ name: mode.link }">{{mode.mode}}
                </b-dropdown-item>
            </b-nav-item-dropdown>
            <button v-if="isRecording" @click="stopRecording()" class="btn skip blink" v-b-tooltip
                    title='Click to stop'>
                <font-awesome-icon :icon="['fas', 'record-vinyl']" size="lg"/>
                Selecting...
            </button>
            <b-button class="setting-b" v-b-modal.modal-center v-if="isViewActive"> Settings
                <font-awesome-icon :icon="['fas', 'cogs']" size="lg"/>
            </b-button>
        </b-navbar-nav>
    </b-navbar>
</template>

<script>
    import store from '../../store'
    import {ALL, DAY, MONTH, WEEK, YEAR} from "../../view/options/PlayingMode";
    import router from '../../router'
    import CommitHashSearch from "./CommitHashSearch";

    export default {
        name: "Navbar",
        components: {CommitHashSearch},
        data() {
            return {
                isRecording: store.getters['settings/isRecording'],
                selectedMode: store.getters['view/playingMode']
            }
        },
        computed: {
            modes() {
                return [
                    {'mode': DAY, link: 'evolutionMatrixVersioned'},
                    {'mode': WEEK, link: 'evolutionMatrixVersioned'},
                    {'mode': MONTH, link: 'evolutionMatrixVersioned'},
                    {'mode': YEAR, link: 'evolutionMatrixVersioned'},
                    {'mode': ALL, link: 'evolutionMatrixVersioned'},]
            },
            menuItems() {
                return [
                    {
                        title: 'Home',
                        link: 'home',
                        icon: 'house'
                    },
                    {
                        title: 'About',
                        link: 'about',
                        icon: 'house'
                    },
                    {
                        title: 'Credits',
                        link: 'credits',
                        icon: 'house'
                    }
                ]
            },
            getIsRecording() {
                return this.$store.getters['settings/isRecording']
            },
        },
        watch: {
            getIsRecording(change) {
                this.isRecording = change;
            }
        },
        methods: {
            isViewActive() {
                return store.getters['view/getCurrentView'] != null
            },
            stopRecording() {
                store.commit('settings/isRecording', false)
            },
            changeMode(e) {
                let element = e.target;
                store.commit('view/playingMode', element.textContent.trim())
                router.push({name: 'evolutionMatrixVersioned', params: {mode: element.textContent.trim()}})

            }
        }
    }
</script>

<style scoped>
    nav {
        background-color: black !important;
        border-bottom: 1px solid #4f4f4f;
        color: white;
    }

    a {
        color: white !important;
    }

    a:hover {
        text-decoration: underline;
        color: cornflowerblue !important;
    }

    .blink {
        color: red;
        animation: blinking 1s step-start infinite;
    }

    @keyframes blinking {
        50% {
            opacity: 0;
        }
    }
</style>
