import {
    AbstractMesh,
    Color3,
    Color4,
    Matrix,
    Mesh,
    MeshBuilder,
    Scene,
    StandardMaterial,
    Vector3
} from "@babylonjs/core";

import {AdvancedDynamicTexture, Control, Ellipse, Line, Rectangle, TextBlock, TextWrapping} from '@babylonjs/gui/2D';
import {CustomMesh} from "./Cuboid";


export interface TextConfig {
    adt: AdvancedDynamicTexture,
    mesh:CustomMesh;
    text:string,
}

export class Text {
    id: string
    adt:AdvancedDynamicTexture;
    mesh:CustomMesh;
    label:TextBlock;
    line:Line;
    rectangle:Rectangle;
    // lineH:Line;
    // rect:Rectangle;
    public constructor(config:TextConfig) {
        this.adt = config.adt;
        this.mesh = config.mesh;
        this.id = config.mesh.id
        var rect1 = new Rectangle();
        rect1.width = "500px";
        // rect1.adaptWidthToChildren = true;
        // rect1.adaptHeightToChildren = true;
        rect1.height = "40px";
        rect1.cornerRadius = 10;
        rect1.color = "black";
        rect1.thickness = 4;
        rect1.background = "white";
        rect1.linkOffsetY = 200;
        this.adt.addControl(rect1);
        rect1.linkWithMesh(this.mesh);
        rect1.linkOffsetY = -100;

        this.rectangle = rect1;

        var label = new TextBlock();
        label.text = config.text;
        label.fontSize = 24
        label.fontWeight = 'bold'
        rect1.addControl(label);
        this.label = label

        var line = new Line();
        line.lineWidth = 2;
        line.color = "black";
        line.y2 = 10;
        line.linkOffsetY = 0;
        this.adt.addControl(line);
        line.linkWithMesh(this.mesh);
        line.connectedControl = rect1;
        this.line = line;
        // var text1 = new TextBlock();
        // text1.text = config.text;
        // text1.color = "black";
        // text1.fontSize = 24;
        // config.adt.addControl(text1);  // must be done BEFORE all link-stuff
        // text1.linkWithMesh(config.mesh);
        // text1.linkOffsetY = -200;
        // text1.linkOffsetX = 0;
        // text1.outlineColor= "green"
        // this.text = text1;
        // this.lineV = this.drawLineV()
        // this.lineH = this.drawLineH()
    }

    public dispose(){
        this.label.dispose()
        this.rectangle.dispose()
        this.line.dispose();
        // this.lineH.dispose();
        // this.rect.dispose();
    }

    // protected drawLineV(){
    //     var line = new Line();
    //     line.lineWidth = 2;
    //     line.color = "black";
    //     line.y2 = 24;
    //     line.linkOffsetX = 0;
    //     this.adt.addControl(line);
    //     line.linkWithMesh(this.mesh);
    //     line.connectedControl = this.text;
    //     return line;
    //     // MeshBuilder.CreateLines("lines", {points: [this.mesh.position.add(new Vector3(this.mesh.scaling.x/2,this.mesh.scaling.y/2,this.mesh.scaling.z/2)), this.mesh.position.add(new Vector3(0,200,0))]}, this.mesh.getScene());
    // }

    protected drawLineH(){
        var line = new Line();
        line.lineWidth = 2;
        line.color = "black";
        // line.y2 = 24;
        line.linkOffsetY = -200;
        line.rotation = Math.PI/2
        // line.linkOffsetX = -100;
        this.adt.addControl(line);
        line.linkWithMesh(this.mesh);
        // line.connectedControl = this.lineV;

        return line;
        // MeshBuilder.CreateLines("lines", {points: [this.mesh.position.add(new Vector3(this.mesh.scaling.x/2,this.mesh.scaling.y/2,this.mesh.scaling.z/2)), this.mesh.position.add(new Vector3(0,200,0))]}, this.mesh.getScene());
    }

}

