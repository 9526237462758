

 let API_URL = ""
 let SOCKET_URL= ""

if(process.env['NODE_ENV'] === "development")
{
    API_URL = "http://localhost:8080";
    SOCKET_URL = "ws://localhost:8080/ws";
}else{
    API_URL = process.env['API_URL'];
    SOCKET_URL = process.env['SOCKET_URL'];
}


export const variables = {
    "API_URL": API_URL,
    "SOCKET_URL": SOCKET_URL
}
