<template>
    <div class="overlay" v-if="commit">
        <span class="title">
            {{new Date(commit['commitTime']).toDateString()}}
        </span>
    </div>
</template>

<script>
    export default {
        name: "CanvasDate",
        props: ['commit'],
        data() {
            return {
                date: new Date()
            }
        }
    }
</script>

<style scoped>

    .overlay {
        margin-top: 5px;
        position: absolute;
        top: 0;
        right: 0;
        color: white;
        padding: 5px;
        pointer-events: none;

    }

    .overlay > span.title {
        font-size:1.75rem;
        padding: 10px;
        background-color: rgba(0, 0, 0, 0.6);
        border-radius: 5px;
    }

    span.connection {
        font-size: 2rem;
    }

</style>
