<template>
<!--    <div v-if="!isMobile">-->
        <div class="container-padded">
            <div class="t1">
                <slot name="canvas-player"/>
            </div>
            <div>
                <slot name="canvas-info"/>
            </div>
            <EvolutionToast/>
            <Loader/>
        </div>
<!--    </div>-->
</template>

<script>
    import Navbar from "./shared/Navbar";
    import EvolutionToast from "./evolution/EvolutionToast";
    import Loader from "./shared/Loader";
    export default {
        name: "BaseLayout",
        components: {Loader, EvolutionToast, Navbar},
        data(){
            return {
                isMobile: false,
            }
        }
    }
</script>

<style scoped>
    .container {
        margin-top: 10px;
    }
</style>

<style>
    .container-padded { padding-top: 60px; }
    @media screen and (max-width: 768px) {
        .container-padded { padding-top: 0px; }
    }
    .t1{
        height: calc(90vh - 160px);
    }
</style>
