import {CubeConfig, Cuboid, CustomMesh} from "./Cuboid";
import {Color3, Mesh, Scene, StandardMaterial, Vector3} from "@babylonjs/core";
import { setScalingAnimationXYZ, setScalingAnimationY} from "./utils/animation";
import addAction from "./utils/action";
import addCuboidClickAction from "./utils/action";
import {M3Class} from "./M3Class";
import {AdvancedDynamicTexture} from "@babylonjs/gui";
import store from "../store";
import {RECORD, REGEX, TAGS} from "../view/options/SettingsModes";

export class M3Package extends M3Class {

    constructor(scene: Scene, adt:AdvancedDynamicTexture) {
        super(scene, adt);
    }

    setDefaultMaterial(mesh:Mesh, value: any):void {

        let oldMat = mesh.material;
        let blueMat = null;
        let intensity = value.intensity;
        let alpha = value.alpha;

        var name = value.nameString;
        var author = value.authorString;
        var re = new RegExp(store.getters['settings/colorizationRegex'].regex);
        let flag = false;
        if (re.test(name) || re.test(author)) {
            flag = true;
        }


        if(store.getters['settings/colorizationMode'] == TAGS && M3Class.containsTag(mesh,value)){
            let color = M3Class.getTagColor(value);

            let mat = mesh.getScene().getMaterialByName(color.toString())
            if(mat == null){
                mat = new StandardMaterial(color.toString(), mesh.getScene());
                mat.freeze();

            }
            let rgb =   {r:color.r, g:color.g, b:color.b}

            // console.log(rgb)
            // @ts-ignore
            mat.diffuseColor = new Color3(rgb.r, rgb.g, rgb.b)
            // @ts-ignore
            mat.specularColor = new Color3(rgb.r, rgb.g, rgb.b);
            mat.backFaceCulling = true;

            // @ts-ignore
            // blueMat.hasAlpha = false;
            mesh.material = mat

        }else if(store.getters['settings/colorizationMode'] == REGEX && flag){
            let color = store.getters['settings/colorizationRegexColor'];
            let mat = mesh.getScene().getMaterialByName(color.toString())
            if(mat == null){
                mat = new StandardMaterial(color.toString(), mesh.getScene());
                mat.freeze();

            }
            // @ts-ignore
            mat.diffuseColor = color
            // @ts-ignore
            mat.specularColor =  color
            mat.backFaceCulling = true;

            // @ts-ignore
            // blueMat.hasAlpha = false;
            mesh.material = mat
        }else  if(store.getters['settings/colorizationMode'] == RECORD && M3Class.isRecordMesh(mesh)){

            let color = M3Class.getRecordColor(mesh)
            // @ts-ignore
            let mat = mesh.getScene().getMaterialByName(color.toString())
            if(mat == null){
                // @ts-ignore
                mat = new StandardMaterial(color.toString(), mesh.getScene());
                mat.freeze();

            }
            // @ts-ignore
            mat.diffuseColor = color
            // @ts-ignore
            mat.specularColor =  color
            mat.backFaceCulling = true;

            // @ts-ignore
            // blueMat.hasAlpha = false;
            mesh.material = mat
        }
        else if(!intensity) {
            let grayMat =<StandardMaterial> mesh.getScene().getMaterialByName("gray");
            if (grayMat != null) {
                mesh.material = grayMat
            } else {
                grayMat = new StandardMaterial("gray", mesh.getScene());
                // grayMat.specularColor = new Color3(.5, .6, .7);
                grayMat.diffuseColor = new Color3(.5, .6, .7)
                grayMat.specularColor = new Color3(0, 0, 0);
                grayMat.backFaceCulling = true;

                // @ts-ignore
                // grayMat.hasAlpha = false;

                grayMat.freeze();
                mesh.material = grayMat
            }
        }else{
            let diffuseMaterial = null;

            if(alpha) {
                diffuseMaterial = <StandardMaterial>mesh.getScene().getMaterialByName((intensity * .5 + intensity * .6 + intensity * .7+alpha).toString());
            }else{
                diffuseMaterial = <StandardMaterial>mesh.getScene().getMaterialByName((intensity * .5 + intensity * .6 + intensity * .7).toString());
            }

            if (diffuseMaterial != null) {
                mesh.material = diffuseMaterial
            } else {
                if(alpha) {
                    diffuseMaterial = new StandardMaterial((intensity * .5 + intensity * .6 + intensity * .7 + alpha).toString(), mesh.getScene());
                }else{
                    diffuseMaterial = new StandardMaterial((intensity * .5 + intensity * .6 + intensity * .7).toString(), mesh.getScene());
                }
                // diffuseMaterial.specularColor = new Color3(intensity * .5, intensity * .6, intensity * .7);
                diffuseMaterial.diffuseColor = new Color3(intensity * .5, intensity * .6, intensity * .7)
                diffuseMaterial.specularColor = new Color3(0, 0, 0);
                diffuseMaterial.backFaceCulling = true;
                // @ts-ignore
                // diffuseMaterial.hasAlpha = false
                if(alpha) {
                    diffuseMaterial.alpha = alpha
                }
                diffuseMaterial.freeze();
                mesh.material = diffuseMaterial
            }
        }
    }

    static setBaseMaterial(mesh:CustomMesh) {
        let value = mesh.config
        if (value) {
            let intensity = value.intensity;
            let alpha = value.alpha;
            if (!intensity) {
                let grayMat = <StandardMaterial>mesh.getScene().getMaterialByName("gray");
                if (grayMat != null) {
                    mesh.material = grayMat
                } else {
                    grayMat = new StandardMaterial("gray", mesh.getScene());
                    // grayMat.specularColor = new Color3(.5, .6, .7);
                    grayMat.diffuseColor = new Color3(.5, .6, .7)
                    grayMat.specularColor = new Color3(0, 0, 0);
                    grayMat.backFaceCulling = true;

                    // @ts-ignore
                    // grayMat.hasAlpha = false;

                    grayMat.freeze();
                    mesh.material = grayMat
                }
            } else {
                let diffuseMaterial = null;

                if (alpha) {
                    diffuseMaterial = <StandardMaterial>mesh.getScene().getMaterialByName((intensity * .5 + intensity * .6 + intensity * .7 + alpha).toString());
                } else {
                    diffuseMaterial = <StandardMaterial>mesh.getScene().getMaterialByName((intensity * .5 + intensity * .6 + intensity * .7).toString());
                }

                if (diffuseMaterial != null) {
                    mesh.material = diffuseMaterial
                } else {
                    if (alpha) {
                        diffuseMaterial = new StandardMaterial((intensity * .5 + intensity * .6 + intensity * .7 + alpha).toString(), mesh.getScene());
                    } else {
                        diffuseMaterial = new StandardMaterial((intensity * .5 + intensity * .6 + intensity * .7).toString(), mesh.getScene());
                    }
                    // diffuseMaterial.specularColor = new Color3(intensity * .5, intensity * .6, intensity * .7);
                    diffuseMaterial.diffuseColor = new Color3(intensity * .5, intensity * .6, intensity * .7)
                    diffuseMaterial.specularColor = new Color3(0, 0, 0);
                    diffuseMaterial.backFaceCulling = true;
                    // @ts-ignore
                    // diffuseMaterial.hasAlpha = false
                    if (alpha) {
                        diffuseMaterial.alpha = alpha
                    }
                    diffuseMaterial.freeze();
                    mesh.material = diffuseMaterial
                }
            }
        }
    }
}
