import {MutationTree} from 'vuex';
import {SceneState} from './types';

export const mutations: MutationTree<SceneState> = {
    rotation(state, payload: { rotation: number, isRotating: boolean }) {
        state.rotation = payload;
    },
    isRotating(state, payload: boolean) {
        state.rotation.isRotating = payload;
    },
    activeMeshes(state, payload: number): any {
        state.activeMeshes = payload;
    },
    allMeshes(state, payload: number): any {
        state.allMeshes = payload;
    },
    fps(state, payload: number): any {
        state.fps = payload;
    },
};
