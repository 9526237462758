import {MutationTree} from 'vuex';
import {Repository, RepositoryState} from './types';

export const mutations: MutationTree<RepositoryState> = {
    repositoryLoaded(state, payload: Repository) {
        state.error = false;
        state.repository = payload;
    },
    repositoryError(state) {
        state.error = true;
        state.repository = undefined;
    },
};
