<template>
    <div class="overlay" style="bottom: 10px; width: 100%; color: black">
        <slot/>
    </div>
</template>

<script>
    import Player from "../../shared/Player";
    export default {
        name: "CanvasPlayer",
        components: {Player},
    }
</script>

<style scoped>

    .overlay {
        position: absolute;
        bottom: 0;

        color: white;
        padding: 5px;
    }


</style>
