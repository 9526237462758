<template>
    <div class="overlay" v-if="isVisible">
        <div class="title">{{this.$route.params.name}} <span class="connection">by</span> {{this.$route.params.owner}} </div>
    </div>
</template>

<script>
    import store from '../../../store'
    export default {
        name: "CanvasTitle",
        data() {
            return {
                isVisible: store.getters['settings/canvasTitle'],
            }
        },
        computed:{
            getTitleSettings(){
                return store.getters['settings/canvasTitle']
            }
        },
        watch:{
            getTitleSettings(change){
                this.isVisible = change;
            }
        }
    }
</script>

<style scoped>

    .overlay {
        margin-top: 5px;
        position: absolute;
        top: 0;
        color: white;
        padding: 5px;
        pointer-events: none;

    }

    .overlay > div.title {
        font-size: 1.75rem;
        padding: 10px;
        background-color: rgba(0, 0, 0, 0.6);
        border-radius: 5px;
    }

    span.connection{
        font-size: 1rem;
    }

</style>
