<template>
    <BaseLayout v-if="socket">
        <template v-slot:canvas-player>
            <b-col cols="12" style="height: 100%">
                <EvolutionMatrixVersionedView :socket="socket">
                    <CanvasTitle/>
                    <CanvasEvolution/>
<!--                    <CanvasDate :commit="commit"/>-->
<!--                    <CanvasCommit :commit="commit"/>-->
                    <CanvasCommitTerminal />
                    <CanvasCommitMessage :commit="commit"/>
                    <CanvasPlayer>
                        <div class="col-sm-12 col-md-6 offset-md-3 mx-auto p-player" style="max-height: 60px; bottom: 0">
                            <div class="text-center">
                                <Player :color="'black'"/>
                            </div>
                        </div>
                    </CanvasPlayer>
                </EvolutionMatrixVersionedView>
            </b-col>
<!--            <div class="col-sm-12 col-md-12 mx-auto" style="max-height: 60px;">-->
<!--                <div class="text-center">-->
<!--                    <Player/>-->
<!--                </div>-->
<!--            </div>-->
        </template>
        <template v-slot:canvas-info>
            <div class="col-sm-12 col-md-12 mx-auto" v-if="versionData != null" style="padding-top: 20px">
                <div class="settings">
                    <EvolutionSettingsModal/>
                </div>
                <template v-if="$store.getters['view/playingMode']=='all'">
                    <Timeline/>
                </template>
                <template v-else>
                    <TimelineDateBased/>
                </template>
<!--                <div class="row">-->
<!--                <div class="col-md-6">-->
<!--                    <CommitListing/>-->
<!--                </div>-->
<!--                </div>-->
            </div>
        </template>
    </BaseLayout>
</template>

<script>
    import BaseLayout from "../BaseLayout";
    import EvolutionMatrixVersionedView from "../canvas/EvolutionMatrixVersioned";
    import {Socket} from "../../Socket";
    import Player from "../shared/Player";
    import RepositoryTitle from "./RepositoryTitle";
    import {mapGetters} from "vuex";
    import EvolutionDataBoxedInformation from "./EvolutionDataBoxedInformation";
    import Timeline from "./Timeline";
    import store from "../../store"
    import CanvasPlayer from "../canvas/sub-components/CanvasPlayer";
    import CanvasTitle from "../canvas/sub-components/CanvasTitle";
    import CanvasDate from "../canvas/sub-components/CanvasDate";
    import CanvasCommit from "../canvas/sub-components/CanvasCommit";
    import CanvasCommitMessage from "../canvas/sub-components/CanvasCommitMessage";
    import CanvasCommitTerminal from "../canvas/sub-components/CanvasCommitTerminal";
    import CanvasEvolution from "../canvas/sub-components/CanvasEvolution";
    import EvolutionInfoToast from "./EvolutionToast";
    import TimelineApex from "./TimelineApex";
    import EvolutionSettingsModal from "./settings/EvolutionSettingsModal";
    import TimelineDateBased from "./TimelineDateBased";
    import CommitHashSearch from "../shared/CommitHashSearch";
    import CommitListing from "../shared/CommitListing";

    export default {
        name: "Evolution",
        components: {
            CommitListing,
            CommitHashSearch,
            TimelineDateBased,
            EvolutionSettingsModal,
            TimelineApex,
            EvolutionInfoToast,
            CanvasEvolution,
            CanvasCommitTerminal,
            CanvasCommitMessage,
            CanvasCommit,
            CanvasDate,
            CanvasTitle,
            CanvasPlayer,
            EvolutionDataBoxedInformation, Timeline, RepositoryTitle, Player, EvolutionMatrixVersionedView, BaseLayout},
        data() {
            return {
                socket: null,
                commit: null,
                versionData: 0,
                selectedClass: null,
                sceneState: this.$store.getters['viewDebug/getSceneState'],
            }
        },
        methods: {
            ...mapGetters('m3Class', ['currentM3Class']),
            async initSocket() {
                let socket = new Socket();
                socket = await socket.connect();
                return socket;
            }

        },
        computed: {
            getCommit() {
                return this.$store.getters['view/getEvolutionCommit']
            },
            getVersionData() {
                return this.$store.getters['view/getEvolutionVersionData']
            },
            getSelectedClass() {
                return this.$store.getters['m3Class/currentM3Class']
            }
        },
        watch: {
            getCommit(change) {
                this.commit = change
            },
            getSelectedClass(change) {
                this.selectedClass = change;
            },
            getVersionData(change) {
                this.versionData = change
            }
        },
        async mounted() {
            store.commit('view/playingMode', this.$route.params.mode);
            if(this.$route.params.mode !== 'all'){
                store.commit('settings/canvasCommitMessage', false)
                store.commit('settings/canvasCommitTerminal', false)
            }else{
                store.commit('settings/canvasCommitMessage', true)
                store.commit('settings/canvasCommitTerminal', true)
            }
            this.socket = await this.initSocket();
            store.commit("view/setRepoId", this.$route.params.owner + '/' + this.$route.params.name)
        }
    }
</script>

<style scoped>
    div, p {
        word-wrap: break-word;
    }

    h5 {
        border-bottom: 1px solid white;
        width: 90%;
    }

    .settings{
        position: fixed;
        right: 0px;
        z-index: 999;
    }
    .setting-b{
        padding: 9px;
        background-color: cornflowerblue;
        border-radius: 5px 0px 0px 5px;
    }
</style>
