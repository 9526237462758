<template>

    <b-modal id="modal-center" centered title="Settings" size="xl">
        <form class="form-row">
            <GeneralSettings :full-screen="fullScreen" :selected="selected" :supported-metrics="supportedMetrics"/>
            <ArtifactSettings :full-screen="fullScreen" :selected="selected" :supported-metrics="supportedMetrics"></ArtifactSettings>

            <NamesPopupSettings :modes="modes" :selected="selected"/>


            <ElisionSettings :modes="elisionModes" :selected="selected"/>


            <ColorizationSettings :active-colorization-tags="activeColorizationTags" :colorization-modes="colorizationModes"
                          :increment-index="incrementIndex" :limit-tags-index="limitTagsIndex" :selected="selected"
                          :set-active-tag="setActiveTag" :set-unactive-tag="setUnactiveTag" :tags="tags" :activeColorizationRecordedMeshes ='activeColorizationRecordedMeshes'
                                  :set-unactive-record="setUnactiveRecord" :change="activeColorizationRecordedMeshes"
                           />


        </form>
    </b-modal>

</template>

<script>
    import {DECL_STMT, FOR, LOC, NOM} from "../../../view/options/SupportedMetrics";
    import {ALL, CHANGES_ONLY, CLASS_ONLY, NONE, PACKAGE_ONLY, RECORD, REGEX, TAGS} from "../../../view/options/SettingsModes";
    import store from "../../../store"
    import GeneralSettings from "./GeneralSettings";
    import NamesPopupSettings from "./NamesPopupSettings";
    import ElisionSettings from "./ElisionSettings";
    import ColorizationSettings from "./ColorizationSettings";
    import ArtifactSettings from "./ArtifactSettings";
    import {Color3} from "@babylonjs/core/Maths/math.color";
import Vue from 'vue'
    export default {
        name: "EvolutionSettingsModal",
        components: {ArtifactSettings, ColorizationSettings, ElisionSettings, NamesPopupSettings, GeneralSettings},
        data() {
            return {
                supportedMetrics: [LOC, FOR, DECL_STMT, NOM],
                modes: [ALL, REGEX, PACKAGE_ONLY, CLASS_ONLY, CHANGES_ONLY, NONE],
                elisionModes:[REGEX,NONE],
                colorizationModes: [NONE,REGEX,RECORD,TAGS],
                tags: [],
                activeColorizationTags: store.getters['settings/colorizationTags'],
                activeColorizationRecordedMeshes: store.getters['settings/recordedMeshes'],
                limitTagsIndex: 20,
                selected: {
                    view: {
                        depth: store.getters['settings/depth'],
                        height: store.getters['settings/height'],
                        dateAuthor: store.getters['settings/height'],
                        canvasTitle: store.getters['settings/canvasTitle'],
                        canvasCommitMessage: store.getters['settings/commitMessage'],
                        canvasCommitTerminal: store.getters['settings/commitTerminal'],
                        canvasEvolution: store.getters['settings/canvasEvolution'],
                        bumpSize: store.getters['settings/bumpSize'],
                        minHeight: store.getters['settings/minHeight'],
                        minWidth: store.getters['settings/minWidth'],
                    },
                    names: {
                        mode: store.getters['settings/nameMode'],
                        regex: store.getters['settings/nameRegex'],
                        text: store.getters['settings/nameText'],
                        hoverEnabled: store.getters['settings/nameHoverEnabled'],
                    },
                    elision:{
                        mode: store.getters['settings/elisionMode'],
                        regex: store.getters['settings/elisionRegex']
                    },
                    colorization:{
                        mode: store.getters['settings/colorizationMode'],
                        regex: {regex: store.getters['settings/colorizationRegex'], color: store.getters['settings/colorizationRegexColor']},
                        isRecording: store.getters['settings/isRecording'],
                        tags: store.getters['settings/colorizationTags'],
                    }
                },
            }
        },
        computed: {
            elisionChange() {
                return this.selected.elision.mode;
            },
            getRepositoryLoadedTags(){
                return store.getters['m3Repository/repositoryData']
            },
            minHeight(){
                return this.selected.view.minHeight;
            },
            minWidth(){
                return this.selected.view.minWidth;
            },
            bumpSize(){
                return this.selected.view.bumpSize
            },
            selectedView(){
                return this.selected.view
            },
            selectedNames(){
                return this.selected.names
            },
            selectedElision(){
                return this.selected.elision
            },
            selectedElisionMode(){
                return this.selected.elision.mode
            }
            ,
            selectedColorization(){
                return this.selected.colorization
            },
            selectedNamesMode(){
                return this.selected.names.mode;
            }
        },
        watch: {
            selectedElisionMode(change){
                let view = store.getters['view/getCurrentView']
                view.resetElision(view)
            },
            selectedNamesMode(change){
                let view = store.getters['view/getCurrentView']
                view.resetADT(view)
                view.root
                //todo add
            },
            minHeight( change){
                if(change < 0  || change == ''){
                    this.selected.view.minHeight = 1;
                }else{
                    this.selected.view.minHeight =  Number(Number(this.selected.view.minHeight).toFixed(0))
                }

                let view = store.getters['view/getCurrentView']
                view.nextVersion()
                view.previousVersion()

            },
            bumpSize(change){
                if(change < 0  || change == ''){
                    this.selected.view.bumpSize = 1;
                }else{
                    this.selected.view.bumpSize =  Number(Number(this.selected.view.bumpSize).toFixed(0))
                }
                let view = store.getters['view/getCurrentView']
                view.nextVersion()
                view.previousVersion()

            },
            minWidth(change){
                if(change < 0  || change == ''){
                    this.selected.view.minWidth = 1;
                }else{
                    this.selected.view.minWidth =  Number(Number(this.selected.view.minWidth).toFixed(0))
                }
                let view = store.getters['view/getCurrentView']
                view.nextVersion()
                view.previousVersion()

            },
            getRepositoryLoadedTags(change){
                this.tags = change[0].mainTags
            },
            selectedView: {
                handler(val, oldVal) {
                    store.commit('settings/setSettingsView', val)
                },
                deep: true
            },
            selectedNames: {
                handler(val, oldVal) {
                    store.commit('settings/setSettingsNames', val)
                },
                deep: true
            },
            selectedElision: {
                handler(val, oldVal) {
                    store.commit('settings/setSettingsElision', val)
                },
                deep: true
            },
            selectedColorization: {
                handler(val, oldVal) {
                    store.commit('settings/setSettingsColorization', val)
                },
                deep: true
            },
        },
        methods: {
            incrementIndex(){
                this.limitTagsIndex += 20;
            },
            setActiveTag: function (e) {
                let clickedElement = e.target;
                let color =   Color3.Random()
                store.commit('settings/colorizationTag', {tag:clickedElement.textContent.trim(), color: color})
                // this.tags = this.tags.filter(item => item !== clickedElement.textContent)

            },
            setUnactiveTag: function (e) {
                let clickedElement = e.target;
                let text = clickedElement.textContent.trim();
                let index = -1 ;
                for (let i = 0; i < this.activeColorizationTags.length ; i++) {
                    if(this.activeColorizationTags[i]!=null && this.activeColorizationTags[i].tag== text){
                        index = i;
                    }
                }
                store.commit('settings/colorizationRemoveTag', {tag:clickedElement.textContent.trim(), index:index})
                // this.activeColorizationTags = this.activeColorizationTags.filter(item => item.text !== clickedElement.textContent)
                // this.tags.push(text)
            },
            setUnactiveRecord: function (e) {
                let clickedElement = e.target;
                let id = clickedElement.id
                let index = -1 ;
                for (let i = 0; i < this.activeColorizationRecordedMeshes.length ; i++) {
                    if(this.activeColorizationRecordedMeshes[i]!=null && this.activeColorizationRecordedMeshes[i].mesh.id == id){
                        index = i;
                    }
                }
                store.commit('settings/colorizationRemoveRecord', {mesh:this.activeColorizationRecordedMeshes[index].mesh, index:index})

                // Vue.set(this.activeColorizationRecordedMeshes, this.activeColorizationRecordedMeshes.length,null)
            },
            fullScreen(){
                let view = store.getters['view/getCurrentView'];
                view._engine.switchFullscreen(true);
                // let isPlaying = store.getters['view/getIsPlaying']
                // if(!isPlaying){
                //     view.play()
                // }
            }
        },

    }
</script>

<style scoped>
    .autocomplete {
        /*the container must be positioned relative:*/
        position: relative;
        display: inline-block;
    }

    input {
        border: 1px solid transparent;
        background-color: #f1f1f1;
        padding: 10px;
        font-size: 16px;
    }

    input[type=text] {
        background-color: #f1f1f1;
        width: 100%;
    }

    input[type=submit] {
        background-color: DodgerBlue;
        color: #fff;
    }

    .autocomplete-items {
        position: absolute;
        border: 1px solid #d4d4d4;
        border-bottom: none;
        border-top: none;
        z-index: 99;
        /*position the autocomplete items to be the same width as the container:*/
        top: 100%;
        left: 0;
        right: 0;
    }

    .autocomplete-items div {
        padding: 10px;
        cursor: pointer;
        background-color: #fff;
        border-bottom: 1px solid #d4d4d4;
    }

    .autocomplete-items div:hover {
        /*when hovering an item:*/
        background-color: #e9e9e9;
    }

    .autocomplete-active {
        /*when navigating through the items using the arrow keys:*/
        background-color: DodgerBlue !important;
        color: #ffffff;
    }

</style>

<style>
    .modal-header {
        color: black;
    }

    form {
        color: black !important;
    }

    .form-inline label {
        justify-content: left;
    }

    .form-inline {
        align-items: flex-start;
    }

</style>
