<template>
    <div class="row rep-title" v-if="dataR">
        <div class="offset-3 col-2 offset-sm-0 col-sm-12 offset-md-3 col-md-2">{{dataR['commits']}} commits</div>
        <div class="col-2 col-sm-12 col-md-2">{{dataR['classHistories']}} class histories</div>
        <div class="col-2 col-sm-12 col-md-2">{{dataR['packageHistories']}} package histories</div>
    </div>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";

    export default {
        name: "RepositoryTitle",
        data() {
            return {
                repoName: null,
                repoOwner: null,
                dataR: null
            }
        },
        methods: {
            ...mapActions('m3Repository', ['fetchData']),
            ...mapGetters('m3Repository', ['repositoryUrl', 'repositoryData']),
            async initRepositoryMetaStore() {
                return await this.fetchData(this.$route.params.owner + '/' + this.$route.params.name);
            },
            extractNames(url) {
                let splitName = url.split('/');
                this.repoName = splitName[splitName.length - 1];
                this.repoOwner = splitName[splitName.length - 2]
            },
        },
        async mounted() {
            await this.initRepositoryMetaStore();
            this.extractNames(this.repositoryUrl());
            this.dataR = this.repositoryData()[0]
        }
    }
</script>

<style scoped>
    .largeFont {
        font-size: x-large;
    }

    .smallFont {
        font-size: smaller;
    }

    .bordered {
        border-bottom: 1px solid #757575;
        margin-bottom: 15px;
    }

    .rep-title {
        text-align: center;
    }

</style>
