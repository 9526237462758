import {MutationTree} from 'vuex';
import {ViewState} from './types';
import {View} from "../../view/View";
import {viewState} from "./index";
import {LinesMesh} from "@babylonjs/core";
import {Socket} from "../../Socket";
import {ALL, DAY} from "../../view/options/PlayingMode";

export const mutations: MutationTree<ViewState> = {
    setCurrentView(state, payload: View): any {
        state.view = payload;
    },
    isPlaying(state, payload: boolean) {
        state.isPlaying = payload;
    },
    playingMode(state, payload: string) {
        state.playingMode = payload;
    },
    evolutionSpeed(state, payload: number) {
        state.error = false;
        if(payload > 0.0625 && payload < 16)
        state.speed = payload
    },
    evolutionVersion(state, payload: number) {
        state.error = false;
        let oldVersion = state.version;
        // console.log('version ', state.markLineIndex , state.versions)
        if (oldVersion +1 == state.versions || state.markLineIndex + 1   == state.versions) {
            if (state.view) {
                // console.log('bnejrfnre ajnf sdnjfn anjks cfjkndas fj')

                state.view.resetMeshesToVersion(payload)
            }
        }
        // if (payload <= state.versions) {
            state.version = payload
        // } else {
        //     state.version = 0;
        // }
    },
    evolutionVersionNext(state, payload: number) {
        state.nextVersion = payload
    },
    evolutionVersions(state, payload: number) {
        state.error = false;
        state.versions = payload
    },
    evolutionCommit(state, payload: any) {
        state.error = false;
        state.commit = payload
    },
    evolutionVersionData(state, payload: any) {
        state.error = false;
        state.versionData = payload
    },
    setRepoId(state, payload: string) {
        state.error = false;
        state.repoId = payload
    },
    evolutionAddRenameLines(state, payload: LinesMesh) {
        state.error = false;
        state.renameLines.meshes.push(payload)
    },
    evolutionRenameLines(state, payload: LinesMesh[]) {
        state.renameLines.meshes = payload
    },
    socket(state, payload: Socket) {
        state.socket = payload
    },
    resetState(state): any {
        state.view = null
        state.isPlaying = false,
        // state.speed = 1
        state.version = 0
        state.renameLines.meshes = []
        state.transcript = []
        state.isLoading = false
            state.markLineIndex=1,
            state.versions= 1,
            state.displayVersion= 1,
            state.versionsList= [],
            state.commit= null,
            state.hoverMesh= null,
            state.playingMode= ALL,
            state.nextVersion= 0,
        Object.assign(state, viewState)
    },
    transcript(state, payload:[]):void{
        state.transcript = payload;
    },
    hoverMesh(state, payload:Text):void{
        state.hoverMesh = payload;
    },

    displayVersion(state, payload:number):void{
        state.displayVersion = payload;
    },

    versionsList(state, payload:number[]):void{
        state.versionsList = payload
    },

    markLineIndex(state, payload:number):void{
        state.markLineIndex = payload
    },

    isLoading(state, payload:boolean):void{
        state.isLoading = payload
    },
};
