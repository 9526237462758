<template>
    <div class=" col-4" style="">
        <div class="col-md-12">
            <h4>Elision Settings</h4>
        </div>
        <div class="form-row align-items-center col-md-12">

            <div class="col-auto">
                <label class="my-1 mr-2" for="inlineFormCustomSelectPref4">Mode</label>
                <select class="custom-select my-1 mr-sm-2" id="inlineFormCustomSelectPref4"
                        v-model="selected.elision.mode">
                    <option v-for="(item,index) in modes" :value="item" :selected="index == 1">{{item}}
                    </option>
                </select>

            </div>
            <div class="col-auto" v-if="selected.elision.mode == 'Custom Regex'">
                <label class="my-1 mr-2" for="inlineFormCustomSelectPref5">Regex Pattern</label>
                <input type="text" id="inlineFormCustomSelectPref5" placeholder="Regex" value="" required
                       v-model="selected.elision.regex">
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        name: 'ElisionSettings',
        props: {
            modes: {},
            selected: {}
        }
    }
</script>
<style scoped>

    input {
        border: 1px solid transparent;
        background-color: #f1f1f1;
        padding: 10px;
        font-size: 16px;
    }

    input[type=text] {
        background-color: #f1f1f1;
        width: 100%;
    }

    input[type=submit] {
        background-color: DodgerBlue;
        color: #fff;
    }

    .autocomplete-items div {
        padding: 10px;
        cursor: pointer;
        background-color: #fff;
        border-bottom: 1px solid #d4d4d4;
    }

    .autocomplete-items div:hover {
        /*when hovering an item:*/
        background-color: #e9e9e9;
    }

</style>
<style>

    form {
        color: black !important;
    }

    .form-inline label {
        justify-content: left;
    }

</style>