import {Module} from 'vuex';
import {getters} from './getters';
import {actions} from './actions';
import {mutations} from './mutations';
import {TimelineState} from './types'
import {RootState} from '../types';

export const timelineState: TimelineState = {
    highlightSeries: [],
    yAxisLabels:[]
};

const namespaced: boolean = true;

export const timeline: Module<TimelineState, RootState> = {
    namespaced,
    state:timelineState,
    getters,
    actions,
    mutations,
};
